import { Loki } from '@inwink/inwinkdb';

let DefaultAdapter: new () => any = null;

// export var currentEvent: States.IEventDataStore;

export function setDefaultAdapter(lokijsAdapter: any) {
    DefaultAdapter = lokijsAdapter;
}

export function getDefaultAdapter() {
    return DefaultAdapter;
}

// export function hasEventRepository(eventid: string) {
//     if (eventDBs[eventid])
//         return true;

//     return false;
// }

export function getDb(name, opts) {
    const options = opts || {};

    // if (DefaultAdapter) {
    //     options.adapter = new DefaultAdapter();
    // } else if (typeof window !== "undefined") {
    //     options.persistenceMethod = "localStorage";
    // }

    const dbFileNAme = InWinkEnv + "-" + name + '.v2.json';
    const db = new Loki(dbFileNAme, options);
    db.initializePersistence({
        adapter: DefaultAdapter ? new DefaultAdapter() : null
    });
    return db;
}
