import { Entities } from '@inwink/entities/entities';
import { States } from '../services';

export declare type IShowLoginCallback = (afterAuth, customArgs, dispatch, getState: () => States.IAppState) => Promise<any>;
export declare type ISimplePromiseCallback = (dispatch, getState: () => States.IAppState) => Promise<any>;
export declare type IGetShareCallback = (i18n: Entities.i18nHelper, dispatch, getState: () => States.IAppState) => void;
export declare type IBoolCallback = (show: boolean, dispatch, getState: () => States.IAppState) => void;
let showLoginCallback: IShowLoginCallback = null;
let logoutCallback: IBoolCallback = null;
let getShareCallback: IGetShareCallback = null;
//let notifCenterCallback: IBoolCallback = null;

export function registerShowLoginCallback(callback: IShowLoginCallback) {
    showLoginCallback = callback;
}

export function registerLogoutCallback(callback: IBoolCallback) {
    logoutCallback = callback;
}

export function registerGetShareCallback(callback: IGetShareCallback) {
    getShareCallback = callback;
}
// export function registerNotifCenterCallback(callback: IBoolCallback) {
//     notifCenterCallback = callback;
// }

export const commonUxActions = {
    showLogin(afterAuth?, customArgs?) {
        return (dispatch, getState: () => States.IAppState) => {
            if (showLoginCallback) {
                return showLoginCallback(afterAuth, customArgs, dispatch, getState);
            }

            return Promise.reject(new Error("showlogin not registered"));
        };
    },

    logout(reload?: boolean) {
        return (dispatch, getState: () => States.IAppState) => {
            if (logoutCallback) {
                return logoutCallback(reload, dispatch, getState);
            }

            return Promise.reject(new Error("logout not registered"));
        };
    },

    getShare(i18n: Entities.i18nHelper) {
        return (dispatch, getState: () => States.IAppState) => {
            if (getShareCallback) {
                return getShareCallback(i18n, dispatch, getState);
            }

            return Promise.reject(new Error("getShare not registered"));
        };
    },

    // notifCenter(show: boolean) {
    //     return (dispatch, getState: () => States.IAppState) => {
    //         if (notifCenterCallback) {
    //             return notifCenterCallback(show, dispatch, getState);
    //         }

    //         return Promise.reject(new Error("notifCenter not registered"));
    //     };
    // }
};
