// requestIdleCallback polyfill
if (typeof window !== "undefined") {
    if (!window.setImmediate) {
        (window as any).setImmediate = function setImmediate(callback) {
            setTimeout(callback, 0);
        };
    }

    (window as any).requestIdleCallback = (window as any).requestIdleCallback
        || function (cb) {
            const start = Date.now();
            return setTimeout(() => {
                cb({
                    didTimeout: false,
                    timeRemaining() {
                        return Math.max(0, 50 - (Date.now() - start));
                    }
                });
            }, 1);
        };

    (window as any).cancelIdleCallback = (window as any).cancelIdleCallback
        || function (id) {
            clearTimeout(id);
        };
}

export const polyfills = "yes";