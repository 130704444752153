// eslint-disable-next-line import/order
import { polyfills } from '../polyfillfunctions';
import { logging } from '@inwink/logging';
import { sessionStoragePersistence } from '@inwink/logging/logging/persistence.sessionstorage';
import { createBrowserHistory } from 'history';
import { setSplashDelay } from '@inwink/appsplash/appsplashservice';
import { appSplashActions } from '../services/splashservice';
import { appStartup } from '../client';
import { setAccessManagerPromise } from '../services/apiaccessprovider';
import { bootstrap } from './bootstrap';
import { getInitialState } from './initialstate';
import { prepareAppState } from './appstate';
import { initStorage } from '../client/storage';
import * as process from 'process';

import '../styles/reset.less';
import '../styles/appthemes.less';
import '../styles/transitions.less';
import "../styles/appshell.less";
import "@@components/appheader/appheader.less";
import "@@components/appmenu/appmenu.less";
import "@@components/dynamicpage/dynamicpage.less";
import "@@components/dynamicpage/dynamicpagebloc.less";
import "@@components/dynamicpage/teaseritems.less";
import "@@components/dynamicpage/teaseritems.carousel.less";


if (!__SERVERSIDE__){
    (global as any).process = process;
}

// variable remplacée à la compil par webpack, permet de définir l'url de base pour les chunks
// eslint-disable-next-line @typescript-eslint/naming-convention
declare let __webpack_public_path__: string;
if (inwink && inwink.config && inwink.config.assetsUrl) {
    let assetsPath = inwink.config.assetsUrl;
    if (!(assetsPath[assetsPath.length - 1] === '/')) {
        assetsPath = `${assetsPath}/`;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    __webpack_public_path__ = assetsPath;
}

logging.addAppender('Console');

setSplashDelay(100);

logging.level = logging.Levels.warn;
if (InWinkEnv === 'dev' || InWinkEnv === 'local' || InWinkEnv === 'sdb' || InWinkEnv === 'stg') {
    logging.level = logging.Levels.debug;
}

if (console && console.timeStamp) {
    console.timeStamp('app startup');
}

if (typeof inwink !== 'undefined') { (inwink as any).logging = logging; }

logging.setPersistence(sessionStoragePersistence);
const logger = logging.getLogger("AppBootstrap");

logger.info('Startup' + polyfills ? "" : "");

initStorage().then(() => {
    return getInitialState();
}).then((initialState) => {
    let startupId = InWinkStartupEventId;
    if (initialState.appMetaData.bypassEventUrl) {
        startupId = initialState?.event?.eventid || initialState?.community?.communityid;
    }

    const history = createBrowserHistory();
    const { store } = appStartup(history, inwink.config, initialState);
    appSplashActions.registerService('webclientbootstrap')(store.dispatch, store.getState);
    if (inwink.config.authMode === "firstpartycookies") {
        setAccessManagerPromise(import(
        /* webpackChunkName: "st-api-ck" */
            "../services/apiaccessproviders/cookiebasedprovider"
        ).then((mod) => {
            const mgr = mod.getCookieBasedAccessManager(() => {
                const state = store.getState();
                return state.i18n.currentLanguageCode;
            }, 20000);
            return mgr;
        }));
    } else {
        setAccessManagerPromise(import(
        /* webpackChunkName: "st-api-oidc" */
            "../services/apiaccessproviders/browseroidcprovider"
        ).then((modprovider) => {
            const mgr = modprovider.getBrowserOidcApiAccessManager(20000, {
                authority: inwink.config.authority,
                authUrl: inwink.config.authUrl,
                clientConfig: inwink.config.clientConfig,
                lang: initialState?.i18n?.currentLanguageCode,
            });
            return mgr;
        }));
    }

    return prepareAppState(initialState, store).then(() => {
        const startPromises : Promise<any>[] = [];
        bootstrap(startupId, store, history, initialState, startPromises);
    });
});
