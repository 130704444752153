import type { IExecModes } from '@inwink/apibase/auth/oidc-configuration';
import { IApiAccessManager } from './apiaccessprovider.definition';

let _apiAccessManager: IApiAccessManager = null;
let _apiAccessManagerPromise: Promise<IApiAccessManager> = null;
let _unauthenticated: () => void = () => {};

export function getAccessManager() : Promise<IApiAccessManager> {
    if (_apiAccessManager) {
        return Promise.resolve(_apiAccessManager);
    }

    if (_apiAccessManagerPromise) {
        return _apiAccessManagerPromise;
    }

    return Promise.reject(new Error("??? not initialized ???"));
}

export function setAccessManagerPromise(apiAccessManager: Promise<IApiAccessManager>) {
    _apiAccessManagerPromise = apiAccessManager;
    apiAccessManager.then((mgr) => {
        _apiAccessManager = mgr;
    });
    return apiAccessManager;
}

export function setAccessManager(apiAccessManager: IApiAccessManager) {
    _apiAccessManager = apiAccessManager;
    return apiAccessManager;
}

export function setOnunauthenticated(callback: () => void) {
    _unauthenticated = callback;
}

export function triggerUnauthenticated() {
    _unauthenticated();
}

export function setCurrentLanguage(lng: string) {
    if (_apiAccessManager) {
        _apiAccessManager.setCurrentLanguage(lng);
    }
}

export function setExecModes(execModes: IExecModes) {
    if (_apiAccessManager?.setExecModes) {
        _apiAccessManager.setExecModes(execModes);
    }
}
