import * as assign from 'lodash/assign';
import { States } from './services';
import * as UXActions from './uxactions';
import { userMessageModule } from '../routes/appmodules';

const INITIAL_STATE = {
    currentMessage: null,
    currentModal: null
} as States.IAppUserMessageState;

export function userMessageReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "USERMESSAGE_SHOW": {
            return assign({}, state, {
                currentMessage: action.payload
            });
        }

        case "USERMESSAGE_HIDE": {
            return assign({}, state, {
                currentMessage: assign({}, state.currentMessage, {
                    show: false
                })
            });
        }

        case "USERMODAL_SHOW": {
            return assign({}, state, {
                currentModal: action.payload
            });
        }

        case "USERMODAL_HIDE": {
            return assign({}, state, {
                currentModal: assign({}, state.currentModal, {
                    show: false
                })
            });
        }

        default:
            return state;
    }
}

UXActions.registerShowLoginCallback((afterAuth, customArgs, dispatch, getState) => {
    return userMessageModule().then((mod) => {
        return mod.loginActions.showLogin(afterAuth)(dispatch, getState);
    });
});
