import * as React from 'react';
import moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { AppTextLabel } from '@inwink/i18n';
import type { IItemTemplateProps } from '@@components/templates/itemtemplate';

interface IShowEndDate extends IItemTemplateProps {
    
}

export class ShowEndDate extends React.Component<IShowEndDate, any> {
    renderEndDate(): JSX.Element | null {
        const { customcontext } = this.props.datacontext;
        const { orderManager } = customcontext;
        const endDate = this.props.data?.initialProduct?.availabilityEndDate;

        if (endDate) {
            let formattedEndDate = endDate;
            if (formattedEndDate.indexOf('+') > -1) {
                formattedEndDate = formattedEndDate.split('+')[0];
            }
            const date = orderManager.timezone
                ? momenttimezone.tz(formattedEndDate, orderManager.timezone)
                : moment(formattedEndDate, 'YYYY[-]MM[-]DD[T]HH[:]mm[:]ss');

            return (
                <div key={`${this.props.datacontext.entity.id}-enddate`} className="bloc-lighttext enddate">
                    <AppTextLabel
                        component="span"
                        i18n="orderregistration.products.endDate"
                        inject={{ date: date.format('L') }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return this.renderEndDate();
    }
}
