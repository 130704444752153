import * as React from 'react';
import { withI18nHelper, translateBag } from '@inwink/i18n';
import { Link } from 'react-router-dom';
import {
    BaseCalendarGeneratorComponent, ICalendarGeneratorProps
} from '@@components/calendargenerator';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { States } from '@@services/services';

@connectwith((state: States.IAppState) => {
    return {
        event: state.event
    };
})
@withI18nHelper()
export class CalendarEventGenerator extends BaseCalendarGeneratorComponent<ICalendarGeneratorProps, any> {
    generate = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        arg.stopPropagation();
        import("./calendargenerator.event.generate").then((mod) => {
            mod.generateEventCalendar(this.props);
        });
    };

    render() {
        const props = {
            className: this.props.className,
            title: this.props.tooltip,
            role: this.props.role,
            style: this.props.style,
            to: "",
            onClick: this.generate
        } as any;

        return <Link {...props}>
            {this.props.icon}
            {translateBag(this.props.i18n, this.props.title)}
        </Link>;
    }
}

export default CalendarEventGenerator;
