import type { AppLabelProps } from '@inwink/i18n/applabel';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import * as React from 'react';

export class AppTextLabelComponent extends React.Component<AppLabelProps, any> {
    editTrad = (key: string, lng: string, newtext: string) => {
        // console.log("edit trad", key, lng, newtext);
        if (global.inwink && (global.inwink as any).previewservice) {
            const page = (this.props as any).pages[(this.props as any).pages.currentPageId];
            (inwink as any).previewservice.send({
                type: "settrad",
                page: page && page.template ? {
                    templatename: page.template.tinyUrl,
                    application: page.template.application,
                    contenttype: page.template.contentType,
                    entityKind: page.context && page.context.entityKind,
                    entityid: page.context && page.context.entityid,
                } : null,
                trad: {
                    key: key,
                    lng: lng,
                    text: newtext
                }
            });
        }
    };

    render() {
        if (InWinkPreview) {
            const p = this.props as any;
            const props = {
                ...this.props,
                editable: p.preview && p.preview.textEdit,
                onEdit: this.editTrad
            };
            return React.createElement(AppTextLabel, props);
        }
        return React.createElement(AppTextLabel, this.props);
    }
}
