import { logging } from '@inwink/logging';
import { IInwinkStore } from '../store';
import {
    rootWebsiteActionsModule,
    eventInitModule, communityInitModule
} from '../routes/appmodules';
import { States } from '../services/services';
import { getAccessManager } from '../services/apiaccessprovider';

const logger = logging.getLogger("AppBootstrap");

export function prepareAppState(initialState: States.IAppState, store: IInwinkStore) : Promise<any> {
    const websiteDo = () => {
        if (initialState?.rootwebsite?.data) {
            const customerId = initialState.rootwebsite.customerid;
            const tenantId = initialState.rootwebsite.tenantid;
            const websiteId = initialState.rootwebsite.websiteid;
            let redirectUrlAlias = null;
            if (!__DEVMODE__ && InWinkPreview) {
                redirectUrlAlias = `w/${customerId}/${tenantId}/${websiteId}`;
            }

            return getAccessManager().then((mgr) => mgr.getTenantRequests(
                initialState.rootwebsite.tenantid,
                redirectUrlAlias
            )).then((requestManager) => {
                return rootWebsiteActionsModule().then((mod) => {
                    return mod.websiteCurrentActions.setCurrentWebsite(
                        requestManager,
                        initialState.rootwebsite.detail,
                        initialState.rootwebsite.data,
                        null,
                        InWinkPreview
                    )(store.dispatch, store.getState).then(
                        null,
                        (err) => {
                            logging.error('error booting root website', err);
                        }
                    ).then(() => {
                        return initialState;
                    });
                });
            });
        }

        return Promise.resolve();
    };

    logger.debug('set current event');
    if (initialState?.event?.data) {
        return getAccessManager().then((mgr) => mgr.getEventRequests(
            initialState.event.eventid,
            initialState.event.tenantid,
            initialState.event.tinyUrl
        )).then((eventrequests) => {
            return eventInitModule().then((mod) => {
                return mod.initEventActions.setCurrentEvent(
                    eventrequests,
                    initialState.event.detail,
                    initialState.event.data,
                    initialState.i18n.currentLanguageCode,
                    false
                )(store.dispatch, store.getState).then(() => {
                    initialState.event.data.save();
                    return initialState;
                }).then(() => websiteDo() as any);
            });
        });
    }

    if (initialState?.community?.data) {
        return getAccessManager().then((mgr) => mgr.getCommunityRequests(
            initialState.community.communityid,
            initialState.community.tenantid,
            initialState.community.tinyUrl
        )).then((communityrequests) => {
            return communityInitModule().then((mod) => {
                return mod.initCommunityActions.setCurrentCommunity(
                    communityrequests,
                    initialState.community.detail,
                    initialState.community.data,
                    false
                )(store.dispatch, store.getState).then(() => {
                    return initialState;
                }).then(() => websiteDo() as any);
            });
        });
    }

    return websiteDo();
}

export function beforeHideSplash(initialState: States.IAppState, store: IInwinkStore) : Promise<any> {
    if (initialState?.community?.data) {
        return communityInitModule().then((mod) => {
            return mod.initCommunityActions.checkCommunityUser()(store.dispatch, store.getState).then(null, (err) => {
                console.error("error checking community user", err);
            });
        });
    }

    return Promise.resolve();
}
