import { Entities } from '@inwink/entities/entities';
import { States } from '../services/services';
import {
    eventInitModule,
    rootWebsiteActionsModule,
    communityInitModule
} from '../routes/appmodules';

export function getInitialState() {
    const promises = [];
    const metadata = {
        origin: 'webclient',
        urls: {
            pageUrl: (event: Entities.IEventDetail, lngcode: string, url: string) => `/${event && event.tinyUrl}/${url}`,
            contentUrl: (url: string) => (inwink.config.assetsUrl || '/') + url,
        },
    };

    const initialState = ((global as any).INITIAL_STATE || { appMetaData: metadata }) as States.IAppState;
    initialState.appMetaData.urls = metadata.urls;

    if (initialState && initialState.rootwebsite && initialState.rootwebsite.websiteid
    // && !initialState.community.communityid
    ) {
        promises.push(rootWebsiteActionsModule().then((mod) => {
            //const websiteData = (global as any).inwinkWebsiteBootstrap;
            initialState.rootwebsite.data = mod.fromWebsiteJSON(
                initialState.rootwebsite.customerid,
                initialState.rootwebsite.websiteid,
                null
            );

            initialState.appMetaData.urls.pageUrl = (event: Entities.IEventDetail, lngcode: string, url: string) => {
                const customerId = initialState.rootwebsite.customerid;
                const tenantId = initialState.rootwebsite.tenantid;
                const websiteId = initialState.rootwebsite.websiteid;
                let rootUrl = '';
                if (InWinkPreview) {
                    rootUrl = `w/${customerId}/${tenantId}/${websiteId}`;
                }
                if (initialState.event?.tinyUrl) {
                    rootUrl = initialState.event?.tinyUrl;
                }
                const supportedLanguages = initialState.rootwebsite?.detail?.configuration?.global?.supportedLanguages;
                if (supportedLanguages && supportedLanguages.length > 1) {
                    return `/${rootUrl}/${lngcode}/${url}`;
                }
                return `/${rootUrl}/${url}`;
            };
        }));
    }

    if (initialState && initialState.community && initialState.community.communityid) {        
        if (initialState.event?.eventid) {
            initialState.appMetaData.urls.pageUrl = (event: Entities.IEventDetail, lngcode: string, url: string) => {
                const customerId = initialState.rootwebsite.customerid;
                const tenantId = initialState.rootwebsite.tenantid;
                const websiteId = initialState.rootwebsite.websiteid;
                let rootUrl = '';
                if (InWinkPreview) {
                    rootUrl = `w/${customerId}/${tenantId}/${websiteId}`;
                }
                if (initialState.event?.tinyUrl) {
                    rootUrl = 'e/' + initialState.event?.tinyUrl;
                }
                const supportedLanguages = initialState.event?.detail?.configuration?.global?.supportedLanguages;
                if (supportedLanguages && supportedLanguages.length > 1) {
                    return `/${rootUrl}/${lngcode}/${url}`;
                }
                return `/${rootUrl}/${url}`;
            };
        } else {
            if (initialState.community.parentcommunityid) {
                initialState.appMetaData.urls.pageUrl = (event: Entities.IEventDetail,
                    lngcode: string, url: string) => {                
                    const rootUrl = 'c/' + initialState.community?.tinyUrl;
                    
                    const supportedLanguages = initialState.community?.detail?.configuration?.global?.supportedLanguages;
                    if (supportedLanguages && supportedLanguages.length > 1) {
                        return `/${rootUrl}/${lngcode}/${url}`;
                    }
                    return `/${rootUrl}/${url}`;
                };
            }
            promises.push(communityInitModule().then((mod) => {
                //const communityData = (global as any).inwinkCommunityBootstrap;
                initialState.community.data = mod.fromCommunityJSON(initialState.community.communityid, null);
            }));
        }
    }

    if (initialState && initialState.event && initialState.event.eventid) {
        delete (initialState as any).routing;
        promises.push(eventInitModule().then((mod) => {
            //const eventData = (global as any).inwinkEventBootstrap;
            initialState.event.data = mod.fromEventJSON(initialState.event.eventid, null);
            if (initialState.appMetaData.bypassEventUrl || InWinkStartupEventId) {
                initialState.appMetaData.urls.pageUrl = (event: Entities.IEventDetail, lngcode: string, url: string) => {
                    if (event) {
                        // eslint-disable-next-line max-len
                        if (event.configuration?.global?.supportedLanguages && event.configuration.global.supportedLanguages.length > 1) {
                            return `/${lngcode}/${url}`;
                        }
                        return `/${url}`;
                    }

                    return `/${url}`;
                };
            } else {
                initialState.appMetaData.urls.pageUrl = (event: Entities.IEventDetail, lngcode: string, url: string) => {
                    if (event) {
                        // eslint-disable-next-line max-len
                        if (event.configuration?.global?.supportedLanguages && event.configuration.global.supportedLanguages.length > 1) {
                            return `/${event.tinyUrl}/${lngcode}/${url}`;
                        }
                        return `/${event.tinyUrl}/${url}`;
                    }

                    return `/${url}`;
                };
            }
        }));
    } else if (InWinkStartupWebsiteId && InWinkStartupCustomerId) {
        promises.push(rootWebsiteActionsModule());
        initialState.rootwebsite = {
            websiteid: InWinkStartupWebsiteId,
            customerid: InWinkStartupCustomerId,
            tenantid: InWinkStartupTenantId,
            data: null,
            detail: null,
        };
    } else if (InWinkStartupEventId) {
        promises.push(eventInitModule());
        initialState.event = {
            eventid: InWinkStartupEventId,
            customerid: InWinkStartupCustomerId,
            tenantid: InWinkStartupTenantId,
            data: null,
            detail: null,
            tinyUrl: null,
            apptemplates: null,
        };
    }

    initialState.appMetaData.urls.contentUrl = (url: string) => (inwink.config.assetsUrl || '/') + url;

    return Promise.all(promises).then(() => initialState);
}
