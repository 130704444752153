import * as React from 'react';
import type { IItemTemplateProps } from '@@components/templates/itemtemplate';
import type { States } from '@@services/services';
import type { IOrderRegistrationBasketItem } from '@inwink/ticketing/definitions';

import "./product.item.less";

interface IProductItemQuantityProps extends IItemTemplateProps {
    stepProps: any;
    location?: States.ILocation;
}

interface IProductItemQuantityState {
    basketItem?: IOrderRegistrationBasketItem;
    quantity: number;
    itemsAvailable?: number;
    quantityMax?: number;
}

export class ProductItemQuantity extends React.Component<IProductItemQuantityProps, IProductItemQuantityState> {
    constructor(props: IProductItemQuantityProps) {
        super(props);
        this.state = {
            basketItem: undefined,
            quantity: 0,
            itemsAvailable: undefined,
            quantityMax: undefined,
        };
    }

    componentDidMount(): void {
        this.updateItemDetails();
    }

    componentDidUpdate(prevProps: IProductItemQuantityProps): void {
        const { data: currentData, datacontext: currentDatacontext } = this.props;
        const { data: prevData, datacontext: prevDatacontext } = prevProps;
    
        const isDataChanged = currentData !== prevData;
    
        const isStepIndexChanged = currentDatacontext?.customcontext?.stepId
            !== prevDatacontext?.customcontext?.stepId;
    
        if (isDataChanged || isStepIndexChanged) {
            this.updateItemDetails();
        }
    }

    updateItemDetails = (): void => {
        const { data, datacontext } = this.props;
        const { customcontext } = datacontext;
        const { orderManager } = customcontext;
        const { id, initialProduct } = data;

        const basketItem = orderManager?.basket?.items?.find((i) => i.productId === id && !i.parentProductId);
        const quantity = basketItem?.quantity ?? 0;
        const quota = initialProduct?.quota;
        const used = initialProduct?.productView?.used ?? 0;
        const quantityMax = initialProduct?.quantityMax;
        const itemsAvailable = quota !== undefined ? quota - used : undefined;

        if (itemsAvailable !== undefined && quantity > itemsAvailable) {
            const newQuantity = itemsAvailable;
            orderManager.changeItemQuantity(id, newQuantity, newQuantity > 0 || orderManager.basket.items.length > 1);
        }

        this.setState({
            basketItem,
            quantity,
            itemsAvailable,
            quantityMax
        });
    };

    changeItemQuantity = (newQuantity: number): void => {
        const { id } = this.props.data;
        const { orderManager } = this.props.datacontext.customcontext;
        const { itemsAvailable, quantityMax } = this.state;

        if (
            itemsAvailable !== undefined && newQuantity > itemsAvailable
                || newQuantity !== undefined && newQuantity > quantityMax
        ) {
            return;
        }

        orderManager.changeItemQuantity(id, newQuantity, newQuantity > 0 || orderManager.basket.items.length > 1);

        this.setState({
            quantity: newQuantity,
        });
    };

    render() {
        const { quantity, itemsAvailable, quantityMax } = this.state;
        const { customcontext } = this.props.datacontext;
        const isFirstStep = customcontext.orderManager?.stepIdx === 0;
        const enableMonoProduct = customcontext.enableMonoProduct;

        const shouldDisplay = !enableMonoProduct || (enableMonoProduct && !isFirstStep);

        return (
            <>
                {shouldDisplay && (
                    <div className='quantity'>
                        <button
                            className='decrease'
                            disabled={quantity == 0}
                            onClick={() => this.changeItemQuantity(quantity - 1)}
                        >
                            <span>-</span>
                        </button>
                        <input
                            type='number'
                            value={quantity}
                            min={0}
                            max={itemsAvailable}
                            onChange={(e) => {
                                const newQuantity = parseInt(e.target.value, 10);
                                if (!isNaN(newQuantity)) {
                                    this.changeItemQuantity(newQuantity);
                                }
                            }}
                        />
                        <button
                            className='increase'
                            onClick={() => this.changeItemQuantity(quantity + 1)}
                            disabled={
                                itemsAvailable !== undefined && quantity >= itemsAvailable
                                    || quantity >= quantityMax
                            }
                        >
                            <span>+</span>
                        </button>
                    </div>
                )}
            </>
        );
    }
}
