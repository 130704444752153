import * as React from 'react';
import { Store } from 'redux';
import { Socket } from 'socket.io-client';
import { logging } from '@inwink/logging';
import { refreshActions } from '@@modules/cmspreview/services/refresh';
import { States } from './services';
import { generateLoadingElt } from './previewservice';

const logger = logging.getLogger("Realtime");
if (__DEBUG__) {
    logger.level = logging.Levels.debug;
}

const connectedSockets : Record<string, Promise<IRealtimeManager>> = {};

export function realtimeHost() {
    return defaultHost;
}

export function sendEvent(eventname, data) {
    try {
        if (typeof (window as any).CustomEvent === "function") {
            const domevent = new CustomEvent(eventname, { detail: data, bubbles: true, cancelable: true });
            document.body.dispatchEvent(domevent);
        } else if (document.createEvent) {
            const evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(eventname, true, true, data);
        }
    } catch (ex) {
        logger.info("cannot dispatch event " + eventname);
    }
}

export const realtimeActions = {
};

// let initialized = false;
// let ensureInitialized: () => void = null;
let connectHost: (host: string, autodisconnect: boolean) => Promise<IRealtimeManager> = () => {
    return Promise.reject(new Error("not initialized"));
};

let defaultHost: string = null;

export function initRealtimeService(host: string, store: Store<States.IAppState>) {
    defaultHost = host;
    // ensureInitialized = () => {
    //     if (!initialized) {
    //         realtimeconnect(host, store);
    //         initialized = true;
    //     }
    // };

    connectHost = (targethost: string, autodisconnect: boolean) => {
        return realtimeconnect(targethost, autodisconnect, store);
    };

    if (InWinkPreview) {
        realtimeconnect(defaultHost, false, store).then((mgr) => {
            setupPreview(mgr.userio, store);
        });
    }
}

export function connectRealtimeHost(host: string, autodisconnect = false) : Promise<IRealtimeManager> {
    return connectHost(host, autodisconnect);
}

function setupPreview(hostio: Socket, store) {
    hostio.on("contenttemplatenotification", (event) => {
        logger.info("[Realtime] contenttemplate notification ", event);
        if (InWinkPreview) {
            sendEvent("inwink.livereload", {});
            const loadingElt = generateLoadingElt();
            document.body.appendChild(loadingElt);
            const atLeast2seconds = new Promise((resolve) => setTimeout(resolve, 2000));

            Promise.all([
                atLeast2seconds,
                refreshActions.refreshTemplates()(store.dispatch, store.getState)
            ]).then(() => {
                document.body.removeChild(loadingElt);
            }, (err) => {
                console.error("refresh error", err);
                document.body.removeChild(loadingElt);
            });
        }/* else{
            eventActions.refreshTemplates()(store.dispatch, store.getState);
        }  */
    });
}

function realtimeconnect(host: string, autodisconnect: boolean, store: Store<States.IAppState>) : Promise<IRealtimeManager> {
    // debugger;
    if (host) {
        let socketContext = connectedSockets[host];
        if (socketContext) {
            return socketContext;
        }
        socketContext = import(
            /* webpackChunkName: "mod-realtimemgr" */ '@@services/realtimeservice.realtimemanager'
        ).then((mod) => {
            const manager = new mod.RealtimeManager(host, autodisconnect, store);
            logger.info("starting realtime on " + host);
            return manager;
        });

        connectedSockets[host] = socketContext;

        return socketContext;
    }

    logger.debug("[Realtime] host is not defined");

    return Promise.resolve(null);
}

export interface IRealtimeManager {
    get userio() : Socket;
    init();
    registerEvent(partitionid: string);
    registerUser(eventid: string);
    attachOwner(parent: any);
    detachOwner(parent: any);
    checkLiveState(eventid: string, livesessionid: string);
    joinLive(livesessionid: string);
    leaveLive(livesessionid: string);
    joinMeeting(eventid: string, meetingId: string);
    leaveMeeting(eventid: string, meetingId: string);
    joinRoundTable(eventid: string, roundTableId: string, participationId: string);
    leaveRoundTable(eventid: string, roundTableId: string, roundTableParticipantId: string);
    joinWatchTogether(eventid: string, watchTogetherId: string, participationId: string);
    leaveWatchTogether(eventid: string, watchTogetherId: string, participationId: string);
    attachEntity(owner: any, partitionid: string, entity: string, entityid: string);
    detachEntity(owner: any, partitionid: string, entity: string, entityid: string);
    joinChatroom(owner: any, partitionid: string, chatkind: string, chatid: string);
    leaveChatroom(owner: any, partitionid: string, chatkind: string, chatid: string);
    joinSpeedMeeting(owner: any, partitionid: string, sessionid: string);
    leaveSpeedMeeting(owner: any, partitionid: string, sessionid: string);
    joinAi(owner: any, partitionid: string, sessionid: string, chatkind: string);
    leaveAi(owner: any, partitionid: string, sessionid: string, chatkind: string);
    eventDiscussionTyping(eventid: string, userid: string, discussionid: string, typing: boolean);
    joinEventDiscussion(discussionid: string);
    leaveEventDiscussion(discussionid: string);
    onReconnect(callback: () => void);
}

export const RealtimeManagerContext = React.createContext<Promise<IRealtimeManager>>(null);

export function withRealtimeManager() {
    return function WithRealtimeManager(target) {
        return React.forwardRef((props, ref) => <RealtimeManagerContext.Consumer>
            {(realtimemgr) => React.createElement(target, {...props, realtime: realtimemgr, ref: ref })}
        </RealtimeManagerContext.Consumer>) as typeof target;
    };
}
