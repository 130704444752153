import * as React from 'react';
import { AppTextLabel } from '@inwink/i18n';
import type { IItemTemplateProps } from '@@components/templates/itemtemplate';

interface IShowAvailabilityInfosProps extends IItemTemplateProps {
}

export class ShowAvailabilityInfos extends React.Component<IShowAvailabilityInfosProps, any> {
    render() {
        const quota = this.props.data?.initialProduct?.quota;
        const used = this.props.data?.initialProduct?.productView?.used;
        const itemsAvailable = quota - used;
        const enableMonoProduct = this.props.datacontext?.customcontext?.enableMonoProduct;
        if (quota) {
            const messageKey = quota > 1 ? 'orderregistration.products.availables' : 'orderregistration.products.available';

            return (
                <div className="bloc-lighttext availability">
                    {itemsAvailable && !enableMonoProduct && (
                        <span>
                            <AppTextLabel
                                component="span"
                                i18n={messageKey}
                                inject={{
                                    quota: itemsAvailable
                                }}
                            />
                        </span>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}
