import { Entities } from '@inwink/entities/entities';
import { getWebmaster } from '../../data/templates';
import { getShareData } from '../../data/metadata';
import { States } from '../services';

export const metadataShareActions = {
    getShare(i18n: Entities.i18nHelper) {
        return (dispatch, getState) => {
            const state: States.IAppState = getState();
            let pagetemplate: Entities.IContentTemplate;
            let currentpage = null;
            const page = state.pages.currentPage;
            if (page && page.id) {
                currentpage = state.pages[page.id];
                if (currentpage) {
                    pagetemplate = currentpage.template;
                }
            }

            const site = state.event || state.rootwebsite || state.community;
            const data = site.data;
            const webmasterconf = getWebmaster(data);
            const share = getShareData(
                i18n,
                pagetemplate?.config?.metadata,
                webmasterconf,
                currentpage?.context?.entity,
                { event: site.detail, community: site.detail, website: site.detail }
            );
            return share;
        };
    }
};
