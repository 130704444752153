import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { setPropertyValue } from '@inwink/utils/methods/setpropertyvalue';
import { getExpressionPredicate } from '@inwink/expressions';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import * as momenttimezone from 'moment-timezone';
import type { States } from '@@services/services';

function initShare() {
    return { url: typeof window !== "undefined" ? window.location.href : null };
}

export function removeHTML(originalString: string) {
    if (!originalString) return undefined;
    return originalString.replace(/(<([^>]+)>)/gi, " ");
}

export function cleanupUrl(url: string) {
    if (url?.endsWith("/")) {
        return url.substring(0, url.length - 1);
    }
    return url;
}

export function getShareData(
    i18n: Entities.i18nHelper,
    metadata: VisualTheme.IContentTemplateConfigMetadata,
    webmaster: VisualTheme.IWebmasterConfig,
    data: any = null,
    datacontext: any = null,
    ssr: States.ISSRState = null
): VisualTheme.IDynamicShareConfiguration {
    let res: VisualTheme.IDynamicShareConfiguration = null;

    if (webmaster && webmaster.dynamicshare) {
        res = res || initShare();
        parseDynamicProperty(i18n, res, webmaster.dynamicshare, datacontext, ssr);
    }

    if (webmaster && webmaster.share) {
        res = res || initShare();
        fillFrom(i18n, res, webmaster.share);
    }

    if (metadata && metadata.share) {
        res = res || initShare();
        fillFrom(i18n, res, metadata.share);
    }

    if (metadata && metadata.dynamicshare && data) {
        res = res || initShare();
        parseDynamicProperty(i18n, res, metadata.dynamicshare, data, ssr);
    }

    if (res && typeof window !== "undefined" && !res.url) {
        res.url = window.location.href;
    }

    return res;
}

const parseDynamicProperty = (
    i18n: Entities.i18nHelper,
    res: VisualTheme.IDynamicShareConfiguration,
    pattern: any,
    data: any,
    ssr: States.ISSRState = null
) => {
    dynamicProperty(i18n, res, pattern, "title", data);
    dynamicProperty(i18n, res, pattern, "description", data);
    dynamicProperty(i18n, res, pattern, "url", data);
    dynamicProperty(i18n, res, pattern, "type", data);
    // dynamicProperty(i18n, res, pattern, "picture", data);

    if (pattern.picture) {
        const pic = getPropertyValue(data, pattern.picture);
        if (pic) {
            if (typeof pic === "string") {
                res.picture = pic;
            } else if (typeof pic === "object") {
                res.picture = pic.thumbUrl || pic.url || res.picture;
            }
        }
    }

    if (pattern.useCurrentUrl) {
        if (typeof window !== "undefined") {
            res.url = window.location.href;
        } else if (res && __SERVERSIDE__ && ssr?.currentPath) {
            res.url = ssr.currentPath;
        } 
    }
};

function dynamicProperty(
    i18n: Entities.i18nHelper,
    res: VisualTheme.IDynamicShareConfiguration,
    dynamicshare: VisualTheme.IDynamicShareConfiguration,
    propname: string,
    data: any,
) {
    const dynamicValue = dynamicshare[propname];
    if (dynamicValue) {
        const val = getPropertyValue(data, dynamicValue);
        if (val) {
            if (typeof val === "object") {
                const _val = i18n.translateBag(val);
                if (_val) {
                    res[propname] = removeHTML(_val);
                }
            } else {
                res[propname] = removeHTML(val);
            }
        } else if (!val
            && !(typeof dynamicValue === "string"
            && RegExp('^[A-Za-z]+\\.[A-Za-z]+$').test(dynamicValue))) {
            // pas de valeur obtenu depuis les données, si valeur "humaine" on l'ajout
            res[propname] = dynamicValue;
        }
    }
}

function fillFrom(
    i18n: Entities.i18nHelper,
    res: VisualTheme.IDynamicShareConfiguration,
    share: VisualTheme.IShareConfiguration
) {
    if (share.title) {
        const val = i18n.translateBag(share.title);
        if (val) {
            res.title = val;
        }
    }
    if (share.description) {
        const val = i18n.translateBag(share.description);
        if (val) {
            res.description = val;
        }
    }
    if (share.picture) {
        if (typeof share.picture === "string" && share.picture) {
            res.picture = share.picture;
        } else {
            const val = i18n.translateBag(share.picture);
            if (val) {
                res.picture = val;
            }
        }
    }
    if (share.url) {
        const val = i18n.translateBag(share.url);
        if (val) {
            res.url = val;
        }
    }
    if (share.type) {
        res.type = share.type;
    }

    if (share.useCurrentUrl) {
        if (typeof window !== "undefined" && !res.url) {
            res.url = window.location.href;
        }
    }
}

/* function dateToEventDate(date: string, timezone: string): string {
    return momenttimezone.tz(date, timezone).format();
} */

// see http://schema.org/Event
export function getMicrodata(
    i18n: Entities.i18nHelper,
    event: Entities.IEventDetail,
    metadata: VisualTheme.IContentTemplateConfigMetadata,
    webmaster: VisualTheme.IWebmasterConfig,
    currentPath: string,
    data: any = null
): string {
    const evt = Object.assign({}, event);

    if (evt.startDate && evt.timeZone) {
        evt.startDate = momenttimezone.tz(evt.startDate, evt.timeZone).format();
    }

    if (evt.endDate && evt.timeZone) {
        evt.endDate = momenttimezone.tz(evt.endDate, evt.timeZone).format();
    }

    const context = {
        event: evt,
        entity: data,
        performers: null,
        timeslot: null,
        sessions: null
    };

    if (data && data.timeslots && data.timeslots.length) {
        const ts = Object.assign({}, data.timeslots[0]);
        if (ts.startDate && evt.timeZone) {
            ts.startDate = momenttimezone.tz(ts.startDate, evt.timeZone).format();
        }

        if (ts.endDate && evt.timeZone) {
            ts.endDate = momenttimezone.tz(ts.endDate, evt.timeZone).format();
        }

        context.timeslot = ts;
    }

    let microdata: VisualTheme.IMicrodataConfiguration = null;

    if (metadata && metadata.microdata) {
        microdata = metadata.microdata;
    } else if (webmaster && webmaster.microdata) {
        microdata = webmaster.microdata;
    }

    if (evt && microdata && microdata.groups) {
        const microdataresult = {};
        applyMicrodata(evt, i18n, microdataresult, microdata, context, currentPath);
        
        return JSON.stringify(microdataresult);
    }

    return null;
}

export function applyMicrodata(
    event: Entities.IEventDetail,
    i18n: Entities.i18nHelper,
    microdataresult: any,
    microdata: VisualTheme.IMicrodataConfiguration,
    context,
    currentPath: string    
) {
    if (microdata && microdata.groups) {
        microdata.groups.forEach((g) => {
            applyGroup(event, i18n, microdataresult, g, context, currentPath);
        });
    }
}

function applyGroup(
    event: Entities.IEventDetail,
    i18n: Entities.i18nHelper,
    microdataresult: any,
    group: VisualTheme.IMicrodataGroupConfiguration,
    context,
    currentPath: string    
) {
    if (group.showIf) {
        const predicate = getExpressionPredicate(group.showIf);
        if (!predicate(context)) {
            return;
        }
    }

    if (group.injectUrl === "current") {
        setPropertyValue(microdataresult, "url", currentPath);
    } else if (group.injectUrl === "event") {
        if (event && event.eventHttpAliases) {
            let protocol = "http://";
            if (event.eventHttpAliases.acceptHttps) {
                protocol = "https://";
            }
            if (event.eventHttpAliases.tinyUrl) {
                setPropertyValue(microdataresult, "url", protocol + event.eventHttpAliases.httpHost);
            } else {
                setPropertyValue(microdataresult, "url", protocol + event.eventHttpAliases.httpHost + "/" + event.tinyUrl);
            }
        } else {
            let url = "";
            if (typeof window !== "undefined") {
                // eslint-disable-next-line max-len
                url = window.location.protocol + "://" + window.location.host + (window.location.port ? ":" + window.location.port : "") + "/" + event.tinyUrl;
                setPropertyValue(microdataresult, "url", url);
            }
        }
    }

    group.items.forEach((i) => {
        applyItem(event, i18n, microdataresult, i, context, currentPath);
    });
}

function applyItem(
    event: Entities.IEventDetail,
    i18n: Entities.i18nHelper,
    microdataresult: any,
    item: VisualTheme.IMicrodataItemConfiguration,
    context,
    currentPath: string   
) {
    if (item.childsMapping) {
        const resultingarray = [];
        const values = getPropertyValue(context, item.field as string);
        if (values && values.length) {
            values.forEach((v) => {
                const itemcontext = Object.assign({}, context, { parentcontext: context, entity: v });
                const itemdata = {};
                item.childsMapping.forEach((g) => {
                    applyGroup(event, i18n, itemdata, g, itemcontext, currentPath);
                });
                const keys = Object.keys(itemdata);
                if (keys.length) {
                    resultingarray.push(itemdata);
                }
            });
            setPropertyValue(microdataresult, item.target, resultingarray);
        }
    } else if (item.childitems) {
        const resultingarray = [];
        setPropertyValue(microdataresult, item.target, resultingarray);
        item.childitems.forEach((i) => {
            const itemdata = {};
            applyMicrodata(event, i18n, itemdata, i, context, currentPath);
            resultingarray.push(itemdata);
        });
    } else {
        let fieldvalue;
        if (item.val && typeof item.val === "string") {
            fieldvalue = item.val;
        } else if (item.val) {
            fieldvalue = i18n.translateBag(item.val as any);
        } else if (item.field) {
            if (typeof item.field === "string") {
                fieldvalue = getFieldValue(i18n, context, item.field);
            } else if (typeof item.field !== "string" && item.field.length) {
                fieldvalue = item.field.map((f) => {
                    return getFieldValue(i18n, context, f);
                }).join(" ");
            }
        }

        if (fieldvalue != null && fieldvalue !== undefined) {
            if (typeof fieldvalue == "string") {
                fieldvalue = removeHTML(fieldvalue);
            }
            setPropertyValue(microdataresult, item.target, fieldvalue);
        }
    }
}

function getFieldValue(i18n: Entities.i18nHelper, context, fieldname: string) {
    const tmp = getPropertyValue(context, fieldname);
    if (typeof tmp === "string") {
        return tmp;
    }
    if (typeof tmp === "object") {
        return i18n.translateBag(tmp);
    }
}
