import * as React from 'react';
import { createStore, applyMiddleware, compose, Store, AnyAction, combineReducers } from 'redux';
import { useStore } from 'react-redux';
import thunk from 'redux-thunk';
import { States } from '../services/services';
import { asyncReducers, rootReducers } from '../services';

export interface IInwinkStore extends Store<States.IAppState, AnyAction> {
    injectReducer: (key: keyof States.IAppState, reducer: any) => void;
}

function _getMiddleware() {
    const middleware = <any>[
    // routerMiddleware(routerHistory),
        thunk,
    ];

    return middleware;
}

function _getEnhancers() {
    let enhancers = [
    // persistState('session', _getStorageConfig()),
    ];

    // Not working, need to add devToolsExtension in the compose
    if (__DEV__ && window.devToolsExtension) {
        enhancers = [...enhancers];
    }

    return enhancers;
}

function _enableHotLoader(store) {
    if ((global as any).__DEV__ && module.hot) {
        module.hot.accept('../services', () => {
            const nextRootReducer = require('../services');
            store.replaceReducer(nextRootReducer);
        });
    }
}

export function configureStore(initialState) : IInwinkStore {
    const createReducer = () => {
        const currentreducers = {
            ...rootReducers,
            ...asyncReducers
        };

        return combineReducers(currentreducers);
    };

    const reducers = createReducer();
    const store: IInwinkStore = createStore(reducers,
        initialState || {},
        (compose as any)(
            applyMiddleware(..._getMiddleware()),
            ..._getEnhancers(),
            ((__DEV__ && window.devToolsExtension) ? window.devToolsExtension() : (f) => f
            )
        )) as any;

    store.injectReducer = (key, asyncReducer) => {
        if (!asyncReducers[key]) {
            asyncReducers[key] = asyncReducer;
            store.replaceReducer(createReducer() as any);
        }
    };
    _enableHotLoader(store);
    return store;
}

export default configureStore;

// export function withReduxStore() {
//     return (target) => {
//         return (function WithReduxStore(props) {
//             const store = useStore();
//             return React.createElement(target, {...props, store: store });
//         }) as typeof target;
//     };
// }

export function wrapReduxStore<T>(component: React.ComponentType<T>): React.ComponentType<T> {
    return function t(props: T) {
        const store = useStore();
        return React.createElement(component, {...props, store});
    };
}