import {AppLabelProps, AppLabel} from '@inwink/i18n/applabel';
import * as React from 'react';

export class AppLabelComponent extends React.Component<AppLabelProps, any> {
    editTrad = (key: string, lng: string, newtext: string) => {
        // console.log("edit trad", key, lng, newtext);
        if (global.inwink && (global.inwink as any).previewservice) {
            (inwink as any).previewservice.send({
                type: "settrad",
                trad: {
                    key: key,
                    lng: lng,
                    text: newtext
                }
            });
        }
    };

    render() {
        if (InWinkPreview) {
            const p = this.props as any;
            const props = {
                ...this.props,
                editable: p.preview && p.preview.textEdit,
                onEdit: this.editTrad
            };
            return React.createElement(AppLabel, props);
        }
        return React.createElement(AppLabel, this.props);
    }
}
