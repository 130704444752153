/* eslint-disable @typescript-eslint/naming-convention */
import { States } from './services';

const INITIAL_STATE = {
    trackingid: null,
    trackers: []
} as States.ITrackingState;

export function trackingReducer(state : States.ITrackingState = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "TRACKING_INIT": {
            return Object.assign({}, state, action.payload);
        }
        case "TRACKING_UPDATE": {
            return Object.assign({}, state, action.payload);
        }
        case "TRACKING_CONSENTMGR": {
            return Object.assign({}, state, {
                consentTracking: action.payload
            });
        }
        default:
            return state;
    }
}

function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

if (InWinkPreview) {
    if (typeof window !== "undefined") {
        if (document.cookie && document.cookie.length > 3000) {
            deleteAllCookies();
        }
    }
}
