import { AppLabel as _AppLabel, AppLabelProps } from '@inwink/i18n/applabel';
import { AppTextLabel as _AppTextLabel} from '@inwink/i18n/apptextlabel';
import { logging } from '@inwink/logging';
import { loadAssets } from '@inwink/react-utils/loadassets';
import * as assign from 'lodash/assign';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import * as React from 'react';
import { connect } from 'react-redux';
import { AppLabelComponent } from './i18nservice.applabel';
import { AppTextLabelComponent } from './i18nservice.apptextlabel';
import { States } from './services';
import { setCurrentLanguage } from './apiaccessprovider';

export { DynLabel } from '@inwink/i18n/dynlabel';
export type { DynLabelProps } from '@inwink/i18n/dynlabel';
export { getMoment } from '@inwink/i18n/utils/getmoment';
export { I18NResourcesContext } from '@inwink/i18n/resourcecontext';

const trads = require("@inwink/tradscompanion/app.json");

const supportedLocales: string[] = require("../trads/supportedlocale.json");

const logger = logging.getLogger("Data");

// var rootTradsUrl = "/";
// const _g = global as any;

const INITIAL_STATE: States.i18nState = {
    defaultlng: null,
    supported: [],
    currentLanguage: null,
    currentLanguageCode: null,    
    currentDateDisplayLanguageCode: null,    
    isReady: false,
    fallbackLanguage: "en"
};

// export function setRootUrl(url) {
//     rootTradsUrl = url;
// }

export function i18nReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    switch (action.type) {
        case "I18N_REFRESH": {
            return assign({}, state);
        }
        case "I18N_SET": {
            const newstate = assign({}, state, {
                currentDateDisplayLanguageCode: action.payload.dateDisplayLanguage,
                currentLanguageCode: action.payload.code,
                currentLanguage: action.payload.translations,
                forcedLanguage: action.payload.forcedLanguage,
                isReady: true
            });

            setCurrentLanguage(action.payload.code);

            return newstate;
        }
        case "I18N_LOADING": {
            const newstate = assign({}, state, {
                currentLanguageCode: action.payload.code,
                currentLanguage: null,
                isReady: false
            });

            return newstate;
        }
        case "I18N_INIT": {
            const newstate = assign({}, state, {
                defaultlng: action.payload.defaultlng,
                supported: action.payload.supported
            });

            return newstate;
        }

        default:
            return state;
    }
}

function getBestMatchingLanguage(
    lngcode: string,
    userLanguages: string[],
    inputdefaultLanguage: string,
    supportedLanguages: string[]
): { language: string, dateDisplayLanguage: string } {
    let defaultLanguage = inputdefaultLanguage;

    if (supportedLanguages && supportedLanguages.length
        && (!defaultLanguage || !(supportedLanguages.indexOf(defaultLanguage) >= 0))) {
        defaultLanguage = supportedLanguages[0];
    }
    let currentLanguage = defaultLanguage || "en";
    let currentDateDisplayLanguage = defaultLanguage || "en";
    if (lngcode && supportedLanguages) {
        if (supportedLanguages.indexOf(lngcode) >= 0) {
            return { language: lngcode, dateDisplayLanguage: lngcode };
        }
    }

    if (userLanguages && supportedLanguages) {
        for (let i = 0; i < userLanguages.length; i++) {
            const lng = userLanguages[i] && userLanguages[i].toLowerCase();
            let idx = supportedLanguages.indexOf(lng);
            if (idx >= 0) {
                currentLanguage = lng;
                currentDateDisplayLanguage = lng;
                logger.debug(
                    "found language " + currentLanguage + " from " + userLanguages[i],
                    supportedLanguages,
                    userLanguages
                );
                break;
            } else if (lng) {
                let tokens = lng.split("_");
                idx = supportedLanguages.indexOf(tokens[0]);
                if (idx >= 0) {
                    currentLanguage = tokens[0];
                    currentDateDisplayLanguage = lng;
                    logger.debug(
                        "found language " + currentLanguage + " from " + userLanguages[i],
                        supportedLanguages,
                        userLanguages
                    );
                    break;
                } else {
                    tokens = lng.split("-");
                    idx = supportedLanguages.indexOf(tokens[0]);
                    if (idx >= 0) {
                        currentLanguage = tokens[0];
                        currentDateDisplayLanguage = lng;
                        logger.debug(
                            "found language " + currentLanguage + " from " + userLanguages[i],
                            supportedLanguages,
                            userLanguages
                        );
                        break;
                    }
                }
            }
        }
    }

    logger.debug("found language " + currentLanguage, supportedLanguages, userLanguages);

    return {
        language: currentLanguage,
        dateDisplayLanguage: currentDateDisplayLanguage
    };
}

export interface IBestLanguage {
    language: string;
    dateDisplayLanguage: string;
    defaultLng: string;
    supportedLanguages: string[];
}

export const actions = {
    autoinit(lngcode?, req?, forcedLanguage?: boolean) {
        return (dispatch, getState: () => States.IAppState) => {
            const lng = actions.determineBestLanguage(lngcode, req)(dispatch, getState);
            const state = getState();
            if (lng.defaultLng !== state.i18n?.defaultlng || lng.supportedLanguages !== state.i18n?.supported) {
                actions.init(lng.defaultLng, lng.supportedLanguages)(dispatch, getState);
            }

            if (!state.i18n.currentLanguageCode || state.i18n.currentLanguageCode !== lng.language) {
                return actions.loadLanguage(lng.language, lng.dateDisplayLanguage, forcedLanguage)(dispatch, getState);
            }

            return Promise.resolve();
        };
    },

    determineBestLanguage(lngcode?, req?) {
        return (dispatch, getState: () => States.IAppState): IBestLanguage => {
            const state = getState();
            let lngconf: { defaultLanguage: string, supportedLanguages: string[] } = null;
            if (state.event && state.event.detail && state.event.detail) {
                lngconf = state.event?.detail?.configuration?.global;
            } else if (state.community && state.community.detail) {
                lngconf = state.community?.detail?.configuration?.global;
            } else if (state.rootwebsite && state.rootwebsite.detail) {
                lngconf = state.rootwebsite?.detail?.configuration?.global;
            }

            const defaultLng = lngconf && lngconf.defaultLanguage;
            const supportedLanguages = lngconf && lngconf.supportedLanguages;
            return determineBestLanguage(defaultLng, supportedLanguages, lngcode, req);
        };
    },

    init(defaultLng: string, supportedLanguages: string[]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            dispatch({
                type: "I18N_INIT",
                payload: {
                    defaultlng: defaultLng,
                    supported: supportedLanguages
                }
            });
        };
    },

    loadLanguage(languageCode: string, dateDisplayLanguage?: string, forcedLanguage = false) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (state.i18n.currentLanguageCode !== languageCode
                || (dateDisplayLanguage && state.i18n.currentDateDisplayLanguageCode !== dateDisplayLanguage)) {
                dispatch({
                    type: 'I18N_SET',
                    payload: {
                        code: languageCode,
                        dateDisplayLanguage: (supportedLocales.filter((l) => l === dateDisplayLanguage).length === 1
                            && dateDisplayLanguage ? dateDisplayLanguage : languageCode),
                        translations: trads,
                        forcedLanguage
                    }
                });

                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return loadLocale(languageCode, dateDisplayLanguage, state.appMetaData.urls.contentUrl("")).then((res) => {
                    if (res) {
                        dispatch({
                            type: 'I18N_REFRESH'
                        });
                    }
                });
            }
        };
    },

    setCurrentLanguage(location: any, history: any, match: any, inputrouteParams: any, newlang: string) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            let newpath = location.pathname;

            const routeParams = match.params;

            if (newpath.indexOf("/" + state.i18n.currentLanguageCode + "/") >= 0) {
                newpath = newpath.replace("/" + state.i18n.currentLanguageCode + "/", "/" + newlang + "/");
            } else if (newpath.lastIndexOf("/" + state.i18n.currentLanguageCode)
                === (newpath.length - state.i18n.currentLanguageCode.length - 1)) {
                const idx = newpath.lastIndexOf("/" + state.i18n.currentLanguageCode);
                newpath = newpath.substr(0, idx) + "/" + newlang + "/";
            } else if (routeParams.eventtinyurl) {
                newpath = newpath.replace("/" + routeParams.eventtinyurl, "/" + routeParams.eventtinyurl + "/" + newlang);
            } else if (state.rootwebsite && state.rootwebsite.isPreview && !state.rootwebsite.hasCurrentEvent) {
                const basepath = '/w/' + state.rootwebsite.customerid
                    + "/" + state.rootwebsite.tenantid
                    + "/" + state.rootwebsite.websiteid.toLowerCase();
                newpath = newpath.toLowerCase().replace(basepath, basepath + '/' + newlang);
            } else {
                newpath = "/" + newlang + newpath;
            }

            history.replace((newpath + location.search).replace('//', '/'));
        };
    }
};

export function labelsToResources(labels) {
    if (labels) {
        const resources = {};
        const keys = Object.keys(labels);
        keys.forEach((k) => {
            const lngs = Object.keys(labels[k]);
            lngs.forEach((lng) => {
                if (!resources[lng]) {
                    resources[lng] = {};
                }
                resources[lng][k] = labels[k][lng];
            });
        });
        return resources;
    }
}

export function determineBestLanguage(defaultLanguage: string, supportedLanguages: string[], lngcode?, req?) {
    let languages;
    if (req && typeof window === "undefined") {
        const headerlanguages = req.headers["accept-language"];
        if (headerlanguages) {
            const res = headerlanguages.split(";");
            languages = [];
            res.forEach((l) => {
                if (l) {
                    const tokens = l.split(",");
                    tokens.forEach((t) => languages.push(t));
                }
            });
        }
    } else if (typeof window !== "undefined") {
        languages = (window as any).navigator.languages
                || (window as any).navigator.userLanguage
                || window.navigator.language;

        if (typeof languages === "string") {
            languages = [languages];
        }
    }

    const lng = getBestMatchingLanguage(lngcode, languages, defaultLanguage, supportedLanguages);
    // eslint-disable-next-line max-len
    logger.verbose(`LANGUAGE bestmatch ${lng.language} date display ${lng.dateDisplayLanguage} userLanguages:${languages} app default:${defaultLanguage} appsupport:${supportedLanguages}`);
    const res: IBestLanguage = {
        language: lng.language,
        dateDisplayLanguage: lng.dateDisplayLanguage,
        defaultLng: defaultLanguage,
        supportedLanguages: supportedLanguages
    };
    return res;
}

const loadedLocaleFiles = {};

(moment as any).locales().forEach((m) => {
    loadedLocaleFiles[m] = true;
});

export function loadLocale(languageCode: string, dateDisplayLanguage: string, rooturl: string) {
    const locales = (moment as any).locales() as string[];
    if (!__SERVERSIDE__ && locales) {
        let filename = null;
        if (dateDisplayLanguage) {
            if (locales.filter((l) => l === dateDisplayLanguage).length === 0
                && supportedLocales.filter((l) => l === dateDisplayLanguage).length === 1) {
                filename = dateDisplayLanguage;
            }
        }

        if (!filename
            && locales.filter((l) => l === languageCode).length === 0
            && supportedLocales.filter((l) => l === languageCode).length === 1) {
            filename = languageCode;
        }

        if (filename) {
            if (loadedLocaleFiles[filename]) {
                return Promise.resolve(false);
            }

            loadedLocaleFiles[filename] = true;
            const url = rooturl + `langs/${filename}.js`;
            console.log(`i18nService loadAssets for lang : ${filename}`);
            return loadAssets([{ url: url, type: 'js' }]).then(() => {
                console.log(`i18nService assets loaded for lang : ${dateDisplayLanguage || languageCode}`);
                moment.locale(dateDisplayLanguage || languageCode);
                momenttimezone.locale(dateDisplayLanguage || languageCode);
                return true;
            });
        }

        if (loadedLocaleFiles[dateDisplayLanguage]) {
            console.log(`i18nService lang already loaded change moment locale : ${dateDisplayLanguage}`);
            moment.locale(dateDisplayLanguage);
            momenttimezone.locale(dateDisplayLanguage);
            return Promise.resolve(true);
        }

        if (loadedLocaleFiles[languageCode]) {
            console.log(`i18nService lang already loaded change moment locale : ${languageCode}`);
            moment.locale(languageCode);
            momenttimezone.locale(languageCode);
            return Promise.resolve(true);
        }
    }

    return Promise.resolve(false);
}

function mapStateToProps(state: States.IAppState) {
    return {
        preview: state.appMetaData.preview,
        pages: state.pages
    };
}

// eslint-disable-next-line import/no-mutable-exports
export let AppTextLabel: new (props: AppLabelProps) => React.Component<AppLabelProps, any> = null;
// eslint-disable-next-line import/no-mutable-exports
export let AppLabel: new (props: AppLabelProps) => React.Component<AppLabelProps, any> = null;

if (InWinkPreview) {
    AppTextLabel = connect(mapStateToProps, null)(AppTextLabelComponent as any) as any;
    AppLabel = connect(mapStateToProps, null)(AppLabelComponent as any) as any;
} else {
    AppTextLabel = _AppTextLabel;
    AppLabel = _AppLabel;
}
