import { actions as pageActions } from '@@services/pageservice';
import { States } from "@@services/services";
import { clearLastSync } from "@@data/globalsync";
import { communityModule, eventModule, rootWebsiteActionsModule } from '@@routes/appmodules';

export const refreshActions = {
    refreshTemplates() {
        return (dispatch, getState: () => States.IAppState) => {
            let state = getState();
            if (!state.event || !state.event.eventid || !state.event.data) {
                if (state.rootwebsite && state.rootwebsite.websiteid) {
                    return rootWebsiteActionsModule().then((mod) => mod.websiteSyncActions.syncWebsiteData(
                        state.rootwebsite.requestManager,
                        state.rootwebsite.customerid,
                        state.rootwebsite.websiteid,
                        state.rootwebsite.data
                    )(dispatch, getState).then((hasChanges) => {
                        if (hasChanges) {
                            state = getState();
                            dispatch({ type: "ROOTWEBSITE_REFRESH" });
                            return pageActions.refreshCurrentPage()(dispatch, getState);
                        }
                    }));
                }
                if (state.community?.communityid) {
                    return communityModule().then((mod) => mod.syncCommunityActions.syncCommunityData(
                        state.community.requestManagers,
                        state.community.communityid,
                        state.community.data
                    )(dispatch, getState).then((hasChanges) => {
                        if (hasChanges) {
                            state = getState();
                            dispatch({ type: "COMMUNITY_REFRESH" });
                            return pageActions.refreshCurrentPage()(dispatch, getState);
                        }
                    }));
                }

                return Promise.resolve();
            }
            clearLastSync();
            return eventModule().then((mod) => mod.syncEventActions.syncEventData(
                state.event.requestManagers,
                state.event.eventid,
                state.event.data
            )(dispatch, getState).then((res: { hasChanges: boolean, eventData: States.IEventDataStore }) => {
                if (res.hasChanges) {
                    state = getState();
                    dispatch({ type: "EVENT_REFRESH", payload: { eventid: state.event.eventid } });
                    return pageActions.refreshCurrentPage()(dispatch, getState);
                }
            }, (err) => {
                console.error(err);
            }));
        };
    },
};
