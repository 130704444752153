import { logging } from '@inwink/logging';
import { ActionQueue, ActionQueueMessage } from '@inwink/actionqueue';
import { eventUserBootstrapModule, eventModule, eventActionQueueModule } from '../routes/appmodules';
import { States } from '../services/services';
import type { IInwinkStore } from '../store';

// import { syncFastContactRecommandations } from '../data/usersync/contactreco';
// import { syncEventMessages } from '../data/eventsync/eventmessages';

const logger = logging.getLogger('Data');

export const actionQueue = new ActionQueue();

export function initActionQueue(store: IInwinkStore) {
    actionQueue.onBeforeFirstQueueing = () => {
        const state = store.getState();
        if (state.event.eventid) {
            return eventActionQueueModule().then((mod) => {
                return mod.initActionQueue();
            });
        }
    };

    actionQueue.getUserId = (state: States.IAppState) => {
        return state?.user?.currentUser?.detail ? state.user.currentUser.detail.id : 'anonymous';
    };

    actionQueue.onQueueProcessed = (error, context: Actions.ActionQueueProcessContext, dispatch, getState) => {
        let state: States.IAppState = getState();

        if (context.refreshUserData) {
            eventUserBootstrapModule().then((mod) => mod.userSyncActions.dataChanged()(dispatch));
        }
        if (context.syncEvent) {
            eventModule().then((mod) => mod.syncEventActions.syncCurrentEvent()(dispatch, getState));
        }

        if (context.syncUser) {
            eventUserBootstrapModule().then((mod) => mod.userSyncActions.syncCurrentUser()(dispatch, getState).then(() => {
                state = getState();
                const currentUser = state.user?.currentUser?.detail;
                if (context.syncUserRecommandations && currentUser && currentUser.allowNetworking) {
                    mod.syncFastContactRecommandations(
                        state.event.requestManagers,
                        logger,
                        state.event.detail.configuration,
                        state.user.currentUser.data, true
                    ).then(() => {
                        mod.userSyncActions.dataChanged()(dispatch);
                    });
                }
            }));
        } else if (context.syncLeads) {
            eventUserBootstrapModule().then((mod) => mod.userSyncActions.syncCurrentUserLeads()(dispatch, getState))
                .then(() => { });
        }

        if (context.syncMeetings) {
            eventUserBootstrapModule().then((mod) => mod.userSyncActions.syncCurrentUserMeetings()(dispatch, getState))
                .then(() => { });
        }

        if (context.syncEventMessages) {
            return eventModule().then((mod) => mod.syncEventActions.syncEventMessages()(dispatch, getState).then(() => {
                mod.syncEventActions.syncCurrentEvent()(dispatch, getState);
            }));
        }
    };
}

export function getQueued<T>(predicate : (msg:ActionQueueMessage<T>) => boolean) {
    return actionQueue.queue.filter((m) => predicate(m));
}

export function getQueuedByType<T>(type:string) {
    return getQueued<T>((m) => m.type === type);
}
