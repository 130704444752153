import { IndexedStorage } from '@inwink/inwinkdb/adapters/indexed_storage';
import * as Data from '../data';

export function initStorage() {
    if (typeof indexedDB !== "undefined") {
        const hasIndexedDb = new Promise((resolve, reject) => {
            const request = indexedDB.open('idbcheck', 1);
            request.onerror = reject;
            request.onsuccess = () => resolve();
        });
        return hasIndexedDb.then(() => {
            // eslint-disable-next-line prefer-arrow-callback
            Data.setDefaultAdapter(function customAdapter() { return new IndexedStorage("InWink"); });
        }, () => {
            return checkAlternateStorage();
        });
    }

    return checkAlternateStorage();
}

function checkAlternateStorage() {
    return new Promise((resolve, reject) => {
        localStorage.setItem("test", "test");
        const res = localStorage.getItem("test");
        if (res === "test") {
            resolve();
        } else {
            reject(new Error("localstorage not available"));
        }
    }).then(() => {
        return import("@inwink/inwinkdb/adapters/local_storage").then((mod) => {
            // eslint-disable-next-line prefer-arrow-callback
            Data.setDefaultAdapter(function customAdapter() { return new mod.LocalStorage(); });
        });
    }, () => {
        return import("@inwink/inwinkdb/adapters/memory_storage").then((mod) => {
            // eslint-disable-next-line prefer-arrow-callback
            Data.setDefaultAdapter(function customAdapter() { return new mod.MemoryStorage(); });
        });
    });
}
