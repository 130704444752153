import { stringifyOnce } from '@inwink/utils/stringifyonce';
import type { IRequestManager } from '../../services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData } from '../index';

function getBrowserType() {
    const test = regexp => {
        return regexp.test(navigator.userAgent);
    };
  
    console.log(navigator.userAgent);
  
    if (test(/opr\//i) || !!(window as any).opr) {
        return 'Opera';
    } else if (test(/edg/i)) {
        return 'Microsoft Edge';
    } else if (test(/chrome|chromium|crios/i)) {
        return 'Google Chrome';
    } else if (test(/firefox|fxios/i)) {
        return 'Mozilla Firefox';
    } else if (test(/safari/i)) {
        return 'Apple Safari';
    } else if (test(/trident/i)) {
        return 'Microsoft Internet Explorer';
    } else if (test(/ucbrowser/i)) {
        return 'UC Browser';
    } else if (test(/samsungbrowser/i)) {
        return 'Samsung Browser';
    } else {
        return 'Unknown browser';
    }
}
  
export function getDeviceInfos() {
    const deviceinfos: any = {
        browser: getBrowserType(),
        //appCodeName: navigator.appCodeName,
        //appName: navigator.appName,
        //appVersion: navigator.appVersion,
        cookieEnabled: navigator.cookieEnabled,
        language: navigator.language,
        platform: navigator.platform,
        languages: navigator.languages && JSON.stringify(navigator.languages),
        //product: navigator.product,
        //productSub: navigator.productSub,
        userAgent: navigator.userAgent
    };
    return deviceinfos;
}

export type TrackingShardType = 'event' | 'community' | 'website' | 'customer' | 'none';

function getErrorData(shardType: TrackingShardType, shardId: string, error, msg?: string, loc?: string) {
    const deviceinfos = getDeviceInfos();

    const message: any = {
        now: new Date().toString(),
        message: msg,
        exception: error ? {} : null
    };

    if (error) {
        if (error.name) message.exception.name = error.name;
        if (error.stack) {
            message.exception.stack = error.stack;
        } else {
            const err = new Error();
            message.exception.stack = err.stack;
        }

        if (error.message) message.exception.message = error.message;
        else if (error.description) message.exception.message = error.description;

        message.asString = error.asString || error.toString();
        if (!error.stack || !error.message) {
            try {
                message.asString = stringifyOnce(error);
            } catch (ex) {
                // toto log ?
            }
        }
    }

    let shardPropName;

    switch (shardType) {
        case 'event':
            shardPropName = 'eventId';
            break;
        case 'community':
            shardPropName = 'communityId';
            break;
        default:
            break;
    }

    const res = {
        level: 4,
        device: deviceinfos,
        version: inwink.config.version,
        location: loc || window.location.href,
        message: JSON.stringify(message)
    };

    if (shardPropName) {
        res[shardPropName] = shardId;
    }

    return res;
}

export function trackInfo(
    requestManager: IRequestManager, shardType: TrackingShardType, shardId: string, msg?: string, payload?: any, loc?: string
): Promise<any> {
    let data:any = getErrorData(shardType, shardId, null, msg, loc);
    data.level = 2;
    if (payload) {        
        data = {...data, fields: {...payload}};
    }

    return trackErrorInternal(requestManager, shardType, shardId, data);
}

export function trackError(requestManager: IRequestManager, shardType: TrackingShardType, shardId: string,
    error, msg?: string, loc?: string): Promise<any> {
    const data = getErrorData(shardType, shardId, error, msg, loc);

    return trackErrorInternal(requestManager, shardType, shardId, data);
}

function trackErrorInternal(requestManager: IRequestManager,
    shardType: TrackingShardType, shardId: string, data): Promise<any> {
    let shardUrlPart;
    switch (shardType) {
        case 'event':
            shardUrlPart = 'e';
            break;
        case 'community':
            shardUrlPart = 'co';
            break;
        case 'website':
            shardUrlPart = 'w';
            break;
        default:
            break;
    }
    let url = "track/exception";
    if (shardUrlPart) {
        url = `track/${shardUrlPart}/${shardId}/exception`;
    }
    return requestManager.postJson(
        url,
        JSON.stringify(data),
        defaultPostHeaderForJsonData
    ).then(null, (err) => {
        console.warn("cannot send exception tracking", err);
    });
}

export function trackErrorRequest(requestManager: IRequestManager, data): Promise<any> {
    return requestManager.postJson("track/exception", JSON.stringify(data), defaultPostHeaderForJsonData).then(null, (err) => {
        console.warn("cannot send exception tracking", err);
    });
}

let wasRegistered = false;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function registerTrackingRequestManager(requestManager: IRequestManager) {
    if (!wasRegistered && !__SERVERSIDE__) {
        wasRegistered = true;
        // logging.addAppender(new ErrorTrackingAppender({
        //     requestmgr: requestManager,
        //     minLevel: LogLevels.warn
        // }));
    }
}

export function mailTracking(requestManager: IRequestManager, eventid: string, mailid: string) {
    // const proxy = getFrontAPIProxy({ eventid: eventid });
    // proxy.get(`t/${eventid}/${mailid}?trackOnly=true`);
    return requestManager.get(`t/${eventid}/${mailid}?trackOnly=true`);
}
