import * as assign from 'lodash/assign';
import * as moment from 'moment';
import type { Entities } from '@inwink/entities/entities';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { getPredicate } from '@inwink/expressions';
import { logging } from '@inwink/logging';
import type { ILogger, ILoggerBase } from '@inwink/logging/logging/basetypes';
import { parse as parseQuery } from '@inwink/utils/querystring';
import { loadModule } from '../routes/appmodules';
import * as BlocsCatalog from '../components/dynamicpage/blocscatalog';
import type { States } from './services';
import { getPageFooter } from '../data/templates';
import { checkIfShownByDate } from '../components/dynamicpage/helpers';
import { getCMSPreview } from './previewservice';
import { isFromServer } from '@@pages/helpers';
import { displayContent } from './itemshelpers';

function frontDataSource() {
    return import('../api/front/datasource');
}

const uilogger = logging.getLogger("UI");
const reduxlogger = logging.getLogger("Redux");

const INITIAL_STATE = {
} as States.IPagesState;

export function pageReducer(state = INITIAL_STATE, action = { type: '', payload: null }) {
    reduxlogger.verbose("REDUX # processing " + action.type, __SERVERSIDE__ ? "" : action.payload);

    switch (action.type) {
        case "PAGE_INITCURRENT": {
            const update = {}; // currentPageId : action.payload.id };
            update[action.payload.id] = action.payload;
            const res = assign({}, state, update, { currentPage: action.payload, currentPageId: action.payload.id });
            return res;
        }
        case "PAGE_SETCURRENTTITLE": {
            uilogger.verbose("setting current page title" + action.payload.id);

            return assign({}, state, {
                currentPage: action.payload
            });
        }
        case "PAGE_SETCURRENTDATA": {
            uilogger.verbose("setting current page " + action.payload.id);
            const update = {};
            const pagedata = state[action.payload.id];
            const updatedpagedata = assign({}, pagedata, action.payload);
            update[action.payload.id] = updatedpagedata;
            return assign({}, state, update, { currentPage: updatedpagedata });
        }

        case "PAGE_SETBLOCDATA": {
            const pagestate = state[action.payload.pageid];
            if (pagestate && action.payload.pagelock === pagestate.pagelock) {
                uilogger.verbose("setting current page bloc data", action.payload.blocid);
                const data = pagestate.data || {};
                const blocdata = assign({}, data[action.payload.blocid], action.payload.data);
                const res = {};
                res[action.payload.blocid] = blocdata;

                const pagedataupdate = assign({}, pagestate.data, res);
                const pageupdate = {};
                pageupdate[action.payload.pageid] = assign({}, pagestate, {
                    data: pagedataupdate
                });

                const newstate = assign({}, state, pageupdate);

                return newstate;
            }

            return state;
        }

        default:
            return state;
    }
}

export type IEntityProviderCallback = (entityKind: string, entityid: string, parententityid?: string) => Promise<any>;
export type IPageFilterCallback = (pagetemplate: Entities.IContentTemplate) => boolean;
export interface IInitCurrentPageOptions {
    entityKind?: string;
    entityid?: any;
    entity?: any;
    parententityid?: any;
    contenttype?: string;
    application?: string;
    pagetemplate?: Entities.IContentTemplate;
    requireEntity?: boolean;
    pageNotFound?: boolean;
    customcontext?: any;
    entityProvider?: IEntityProviderCallback;
    pageFilter?: IPageFilterCallback;
}

const remoteEventEntities: Record<string, { selects: any }> = {
    roundtablehall: {
        selects: {
            $all: true
        }
    }
};

export const actions = {
    setCurrentPageTitle(id, title) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (InWinkPreview) {
                if (typeof window !== "undefined") {
                    getCMSPreview().then((previewservice) => {
                        previewservice.send({ type: "SetPageTitle", title: title });
                    });
                }
            }

            if (!state.pages.currentPage || state.pages.currentPage.title !== title) {
                dispatch({
                    type: "PAGE_SETCURRENTTITLE",
                    payload: {
                        id: id,
                        title: title
                    }
                });
            }
        };
    },

    setCurrentPage(template: States.ICurrentPageState) {
        return (dispatch) => {
            dispatch({ type: "PAGE_SETCURRENTDATA", payload: template });
        };
    },

    setBlocData(pageid, pagelock, blocid, data) {
        return (dispatch) => {
            dispatch({ type: "PAGE_SETBLOCDATA", payload: { pageid: pageid, blocid: blocid, pagelock: pagelock, data: data } });
        };
    },

    refreshCurrentPageBlocs() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            const currentpageid = (state.pages as any).currentPageId;
            const page: States.ICurrentPageState = (state.pages as any)[currentpageid];
            const promise = initCurrentPageBlocs(page, page.template, state, dispatch, getState, false, false,);
            return promise;
        };
    },

    patchCurrentPageEntity(patch: any) {
        return (dispatch, getState) => {
            const state = getState();
            const currentpageid = (state.pages as any).currentPageId;
            const page: States.ICurrentPageState = (state.pages as any)[currentpageid];
            const newcontext = Object.assign({}, page.context, {
                entity: Object.assign({}, page.context?.entity, patch)
            });

            dispatch({
                type: "PAGE_SETCURRENTDATA",
                payload: {
                    id: currentpageid,
                    context: newcontext
                }
            });
        };
    },

    patchCurrentPageCustomContext(patch: any) {
        return (dispatch, getState) => {
            const state = getState();
            const currentpageid = (state.pages as any).currentPageId;
            const page: States.ICurrentPageState = (state.pages as any)[currentpageid];
            const newcustomcontext = Object.assign({}, page.customcontext, patch);

            dispatch({
                type: "PAGE_SETCURRENTDATA",
                payload: {
                    id: currentpageid,
                    customcontext: newcustomcontext
                }
            });
        };
    },

    refreshCurrentPageEntity() {
        return (dispatch, getState) => {
            const state = getState();
            const currentpageid = (state.pages as any).currentPageId;
            const page: States.ICurrentPageState = (state.pages as any)[currentpageid];
            if ((page.context as any).entityProvider) {
                const provider: IEntityProviderCallback = (page.context as any).entityProvider;
                provider(
                    page.context.entityKind,
                    page.context.entityid,
                    page.context.parententityid
                ).then((entity) => {
                    const newcontext = Object.assign({}, page.context, {
                        entity: entity
                    });

                    dispatch({
                        type: "PAGE_SETCURRENTDATA",
                        payload: {
                            id: currentpageid,
                            context: newcontext
                        }
                    });
                });
            }
        };
    },

    refreshCurrentPage() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            let pageFilter;
            let tinyUrl = state.event?.tinyUrl;
            const currentpageid = (state.pages as any).currentPageId;
            const page: States.ICurrentPageState = (state.pages as any)[currentpageid];

            if (!page) return Promise.resolve();

            if (!tinyUrl && state?.rootwebsite) {
                const rootWebsite = state.rootwebsite;
                tinyUrl = `w/${rootWebsite?.customerid}/${rootWebsite.tenantid}/${rootWebsite.websiteid}`;
            }
            if (state.community && page?.template?.groupId) {
                const groupId = page?.template?.groupId;
                pageFilter = (pagetemplate) => pagetemplate?.groupId === groupId;
            }

            return actions.initCurrentPage(
                page.pageargs,
                page.tinyurl,
                {
                    entityKind: page.context?.entityKind,
                    entityid: page.context?.entityid,
                    parententityid: (page.context as any)?.parententityid,
                    entity: page.context?.entity,
                    contenttype: page.template?.contentType,
                    application: page.template?.application,
                    entityProvider: (page.context as any).entityProvider,
                    pageFilter: pageFilter,
                    customcontext: page.customcontext
                },
                true
            )(dispatch, getState) as Promise<any>;
        };
    },

    getPageTemplate(
        application: string,
        contenttype: string,
        tinyurl: string,
        pageFilter?: IPageFilterCallback) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            if (application === "companion") {
                return state.event.data.templates.data.find((ct) => {
                    if (pageFilter && !pageFilter(ct)) {
                        return false;
                    }
                    return ct.contentType === contenttype && ct.tinyUrl === tinyurl;
                });
            }

            if (application === "rootwebsite") {
                return state.rootwebsite.data.templates.data.find((ct) => {
                    if (pageFilter && !pageFilter(ct)) {
                        return false;
                    }
                    return ct.contentType === contenttype && ct.tinyUrl === tinyurl;
                });
            }

            if (application.startsWith("community")) {
                if (state.community.apptemplates && state.community.apptemplates[application]) {
                    const templates = state.community.apptemplates[application];
                    return templates.find((ct) => {
                        if (pageFilter && !pageFilter(ct)) {
                            return false;
                        }
                        return ct.contentType === contenttype && ct.tinyUrl === tinyurl;
                    });
                }
                return state.community.data.templates.data.find((ct) => {
                    if (pageFilter && !pageFilter(ct)) {
                        return false;
                    }
                    return ct.contentType === contenttype && ct.tinyUrl === tinyurl && ct.application === application;
                });
            }
            if (state.event?.apptemplates && state.event.apptemplates[application]) {
                const templates = state.event.apptemplates[application];
                return templates.find((ct) => {
                    if (pageFilter && !pageFilter(ct)) {
                        return false;
                    }
                    return ct.contentType === contenttype && ct.tinyUrl === tinyurl;
                });
            }
        };
    },

    initCurrentPage(args, templatename: string, options: IInitCurrentPageOptions, isRefresh = false) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            let logger: ILoggerBase = uilogger;
            let entityKind = (options && options.entityKind);
            let entityid = (options && options.entityid);
            const application = (options && options.application) || "companion";
            const contenttype = (options && options.contenttype) || "page";

            if (state.appMetaData?.logcontext) {
                logger = uilogger.context(state.appMetaData.logcontext);
            }
            logger.verbose("init page " + templatename + " " + contenttype);

            const id = application + "." + contenttype + "." + templatename;

            let pagedata = {};
            let pagecontext = null;
            let pagetemplate = null;
            const existing = state.pages[id];
            const isSamePage = existing && existing.context.entityKind === entityKind && existing.context.entityid === entityid;
            if (isSamePage) {
                pagedata = existing.data;
                if (isFromServer()) {
                    pagetemplate = existing.template;
                    pagecontext = existing.context;
                }
            }

            if (__SERVERSIDE__ && existing) {
                return existing.ready;
                // return Promise.resolve({
                //     hasAsyncUpdate: false,
                //     page: existing
                // });
            }

            if (__SERVERSIDE__ && state.appMetaData?.trackStep) {
                state.appMetaData?.trackStep("init page " + id);
            }

            let pageReadyResolved = null;
            let pageReadyError = null;
            const pageReadyPromise = new Promise((resolve, reject) => {
                pageReadyResolved = resolve;
                pageReadyError = reject;
            });

            const currentPage: States.ICurrentPageState = {
                id: id,
                tinyurl: templatename,
                pagelock: new Date().getTime(),
                data: pagedata,
                template: pagetemplate,
                context: pagecontext || {
                    entityKind: entityKind,
                    entityid: entityid,
                    parententityid: options?.parententityid,
                    entity: options?.entity,
                    fieldtemplate: null,
                    contenttype: contenttype,
                    application: application
                },
                pageargs: args,
                ready: pageReadyPromise,
                restoreScroll: existing?.restoreScroll,
                customcontext: options?.customcontext
            };

            if (options?.pageNotFound) {
                currentPage.notFound = options.pageNotFound;
            }

            let storedata: States.IDataStoreWithTemplates;
            if (application.startsWith("community")) {
                storedata = state.community?.data;
            } else if (application === "rootwebsite") {
                storedata = state.rootwebsite?.data;
            } else {
                storedata = state.event?.data;
            }

            if (storedata) {
                const loadPage = (data) => {
                    let promise = Promise.resolve({ hasAsyncUpdate: false });
                    if (data.hasData) {
                        currentPage.template = (options?.pagetemplate)
                            || actions.getPageTemplate(
                                application, contenttype, templatename, options?.pageFilter
                            )(dispatch, getState);

                        if (!entityKind && currentPage.template && currentPage.template.relatedTo) {
                            entityKind = currentPage.template.relatedTo;
                        }

                        if (!entityid && args && args.location && currentPage?.template?.config?.getEntityIdFrom) {
                            const query = parseQuery(args.location.search);
                            entityid = query[currentPage.template.config.getEntityIdFrom];
                        }

                        if ((contenttype === "contentpage" || contenttype === "registration") && !currentPage.template) {
                            currentPage.notFound = true;
                        }

                        if (entityKind && storedata === data) {
                            promise = promise.then(() => {
                                return getContext(
                                    storedata,
                                    entityKind,
                                    entityid,
                                    options?.parententityid,
                                    options?.entity,
                                    contenttype,
                                    application,
                                    options?.entityProvider
                                ).then((context) => {
                                    currentPage.context = context;

                                    if (entityKind === "Person" && !currentPage.context.entity) {
                                        const user = state.user && state.user.currentUser && state.user.currentUser.detail;
                                        if (user && user.id === currentPage.context.entityid) {
                                            currentPage.context.entity = user;
                                        }
                                    }
                                    if (options && options.requireEntity && !currentPage.context.entity) {
                                        currentPage.notFound = true;
                                    }
                                    return {} as any;
                                }, (err) => {
                                    const pageError: PageContextError = err;
                                    if (pageError && pageError.message === "notfound") {
                                        currentPage.notFound = true;
                                        return {} as any;
                                    } else if (pageError && pageError.message === "wrapwithuserrestricted") {
                                        currentPage.wrapWithUserRestricted = true;
                                        return {} as any;
                                    }
                                    throw err;
                                });
                            });
                        }

                        if (currentPage.context.entityKind && currentPage.context.entityid && state.event?.eventid) {
                            if (__SERVERSIDE__ && state.appMetaData?.trackStep) {
                                state.appMetaData?.trackStep("load entity "
                                    + currentPage.context.entityKind + " "
                                    + currentPage.context.entityid);
                            }

                            const conf = remoteEventEntities[currentPage.context.entityKind.toLowerCase()];
                            if (conf) {
                                promise = promise.then((res) => {
                                    return frontDataSource().then((modDataSource) => {
                                        const ds = modDataSource.getFrontEntityDataSource(
                                            state.event.requestManagers.apiFront,
                                            currentPage.context.entityKind,
                                            currentPage.context.entityKind
                                        );
                                        return ds.query(({
                                            filters: {
                                                id: currentPage.context.entityid
                                            },
                                            selects: conf.selects
                                        })).then((queryres) => {
                                            if (queryres && queryres.data && queryres.data.length) {
                                                currentPage.context.entity = queryres.data[0];
                                            }
                                        }, (err) => {
                                            logger.error("error loading entity", err);
                                        }).then(() => {
                                            return res;
                                        });
                                    });
                                });
                            }
                        }

                        if (currentPage.template) {
                            promise = promise.then(() => initCurrentPageBlocs(
                                currentPage,
                                currentPage.template,
                                state,
                                dispatch,
                                getState,
                                false,
                                false,
                                !isRefresh
                            ));
                        } else if (!__SERVERSIDE__) {
                            logger.error("init page, template not found for "
                                + application + "." + contenttype + "." + templatename
                                + " with templates: " + data.templates?.data?.length);
                        } else {
                            logger.warn("init page, template not found for "
                                + application + "." + contenttype + "." + templatename
                                + " with templates: " + data?.templates?.data?.length);
                        }

                        if (InWinkPreview) {
                            if (typeof window !== "undefined" && window.parent) {
                                window.parent.postMessage({
                                    type: "CurrentPage",
                                    event: state.event?.detail,
                                    website: state.rootwebsite?.detail,
                                    currentUrl: args.location?.pathname + args.location?.search,
                                    page: {
                                        application: (currentPage.template && currentPage.template.application) || application,
                                        tinyUrl: (currentPage.template && currentPage.template.tinyUrl),
                                        templatename: templatename,
                                        entityKind: entityKind,
                                        entityid: entityid,
                                        groupId: state.communityGroupContext?.groupId,
                                        contenttype: (currentPage.template && currentPage.template.contentType) || contenttype
                                    }
                                }, "*");
                            }
                        }

                        // return BPromise.try(() => {
                        //     if (entityKind) {
                        //         currentPage.context = getContext(data, entityKind, entityid);
                        //     }
                        //     if (currentPage.template) {
                        //         return initCurrentPageBlocs(currentPage, currentPage.template, data, dispatch);
                        //     }
                        // }).then(() => {
                        //     actions.setCurrentPage(currentPage)(dispatch);
                        // });
                    } else {
                        logger.warn("init page, event has no data");
                    }

                    return promise.then(null, (err) => {
                        if (err instanceof PageContextError) {
                            currentPage.notFound = true;
                            return {
                                hasAsyncUpdate: false,
                                page: currentPage
                            };
                        }

                        return Promise.reject(err);
                    });
                };

                if (storedata.hasData) {
                    loadPage(storedata).then((res) => {
                        return {
                            hasAsyncUpdate: res && res.hasAsyncUpdate,
                            page: currentPage
                        };
                    }).then(pageReadyResolved, pageReadyError);
                } else {
                    storedata.ready.then(() => {
                        return loadPage(storedata);
                    }).then((res) => {
                        return {
                            hasAsyncUpdate: res && res.hasAsyncUpdate,
                            page: currentPage
                        };
                    }).then(pageReadyResolved, pageReadyError);
                }
                dispatch({ type: "PAGE_INITCURRENT", payload: currentPage });
            } else {
                logger.warn("init page, event data not initialized");
                dispatch({ type: "PAGE_INITCURRENT", payload: currentPage });
                pageReadyResolved({
                    hasAsyncUpdate: false,
                    page: currentPage
                });
            }
            return currentPage.ready;
        };
    }
};

class PageContextError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(message: string) {
        super(message);
    }
}

function getContext(
    data: States.IDataStore,
    entityKind: string,
    entityid: string,
    parententityid: string,
    inputentity: any,
    contenttype: string,
    application: string,
    entityProvider: IEntityProviderCallback
): Promise<States.ICurrentPageContext> {
    let entity = inputentity;
    let fieldtemplate;

    if (entityid && !inputentity && entityProvider) {
        const entityKindLower = entityKind.toLowerCase();
        return entityProvider(entityKind, entityid, parententityid).then((res) => {
            fieldtemplate = (data as any).fieldtemplates?.data.filter((ft) => {
                return ft.entityName.toLowerCase() === entityKindLower;
            })[0];
            const result: States.ICurrentPageContext = {
                entity: res,
                entityKind: entityKind,
                entityid: entityid,
                parententityid: parententityid,
                fieldtemplate: fieldtemplate,
                contenttype: contenttype,
                application: application
            };

            (result as any).entityProvider = entityProvider;

            return result;
        }).then(null, (err) => {
            if (err.status && err.status === 403) {
                return Promise.reject(new PageContextError("forbidden"));
            }
            if (err.status && err.status === 404) {
                return Promise.reject(new PageContextError("notfound"));
            }
            if (err.entityStatus && err.entityStatus === 403) {
                return Promise.reject(new PageContextError("wrapwithuserrestricted"));
            }
            return Promise.reject(new PageContextError("error"));
        });
    }

    const eventdata: States.IEventDataStore = data as any;
    const entityKindLower = entityKind.toLowerCase();
    if (entityKindLower) {
        if (entityKindLower === "speaker") {
            fieldtemplate = eventdata.fieldtemplates.data.filter((ft) => {
                return ft.entityName.toLowerCase() === "person";
            })[0];
        } else {
            fieldtemplate = eventdata.fieldtemplates.data.filter((ft) => {
                return ft.entityName.toLowerCase() === entityKindLower;
            })[0];
        }
    }

    if (entityid && !entity) {
        if (entityKindLower === "session") {
            entity = eventdata.sessions.data.find((s) => s.id === entityid);
            if (entity) {
                entity = {
                    ...entity,
                    speakers: entity.speakers && entity.speakers.map((s) => {
                        const speaker = eventdata.speakers.data.find((sp) => sp.id === s.id);
                        if (speaker) {
                            return speaker;
                        }

                        return s;
                    })
                };
            }
        } else if (entityKindLower === "speaker" || entityKindLower === "person") {
            entity = eventdata.speakers.data.find((s) => s.id === entityid);
        } else if (entityKindLower === "exhibitor") {
            entity = eventdata.exhibitors.data.find((e) => e.id === entityid);
        } else if (entityKindLower === "eventtheme") {
            entity = eventdata.eventthemes.data.find((e) => e.id === entityid);
        } else if (entityKindLower === "journey") {
            entity = eventdata.journeys.data.find((e) => e.id === entityid);
        } else if (entityKindLower === "eventmessage") {
            entity = eventdata.eventmessage.data.find((e) => e.id === entityid);
        } else if (entityKindLower === "exhibitoroffering") {
            entity = eventdata.exhibitorofferings.data.find((e) => e.id === entityid);
        }
    }

    const result: VisualTheme.IPageContext = {
        entity: entity,
        entityKind: entityKind,
        entityid: entityid,
        fieldtemplate: fieldtemplate,
        contenttype: contenttype,
        application: application
    };

    return Promise.resolve(result);
}

function asyncBlocMatchConstraint(bloc: BlocsCatalog.IAsyncBlocConstraints, currentState: States.IAppState) {
    if (bloc?.user) {
        if (!currentState.user?.currentUser?.detail?.id) {
            return false;
        }
    }
    if (bloc?.registered) {
        if (!currentState.user?.currentUser?.detail?.isRegistered) {
            return false;
        }
    }
    if (bloc?.allowNetworking) {
        if (!currentState.user?.currentUser?.detail?.allowNetworking) {
            return false;
        }
    }
    return true;
}

export function initCurrentPageBlocs(
    currentPage: States.ICurrentPageState,
    template: Entities.IContentTemplate,
    data: States.IAppState,
    dispatch: (action) => void,
    getState: () => States.IAppState,
    isBootstrap = false,
    setCurrent = true,
    isPageInit = false
) {
    const promises = [];
    const state = getState();
    let logger: ILogger = state.ssr?.logger || uilogger;
    if (data.appMetaData && data.appMetaData.logcontext) {
        logger = uilogger.context(data.appMetaData.logcontext);
    }

    if (template && template.config) {
        // if (__SERVERSIDE__ && state.appMetaData?.trackStep) {
        //     state.appMetaData?.trackStep("init page blocs");
        // }
        logger.verbose("init current page blocs for " + template.tinyUrl);
        let pagetemplate: VisualTheme.IContentTemplateConfig;
        try {
            pagetemplate = template.config;
            const datacontext = {
                i18nstate: data.i18n,
                eventstate: data.event,
                communitystate: data.community,
                userstate: data.user,
                event: data.event?.detail,
                rootwebsite: data.rootwebsite?.detail,
                rootwebsitestate: data.rootwebsite,
                user: data.user?.currentUser?.detail || null,
                entity: currentPage?.context?.entity || null,
                entityKind: currentPage?.context?.entityKind || null,
                fieldtemplate: currentPage?.context?.fieldtemplate || null,
                customcontext: currentPage?.customcontext
            } as Entities.IPageDataContext;

            let pageBlocs = pagetemplate.blocs || [];
            if (pagetemplate.sidebar && pagetemplate.sidebar.blocs) {
                pageBlocs = pageBlocs.concat(pagetemplate.sidebar.blocs);
            }

            const pagefooter = getPageFooter(data.event?.data || data.community?.data);
            if (pagefooter) {
                pageBlocs = pageBlocs.concat(((pagefooter as any) as Entities.IContentTemplate).config.blocs);
            }
            pageBlocs.filter((b, idx) => filterBlocs(data.event, data.community, datacontext, b, idx)).forEach((bloc) => {
                if (bloc.content) {
                    bloc.content.filter(
                        (c, cidx) => internalFilterBlocContent(data.user, datacontext, c, cidx)
                    ).forEach((blocContent) => {
                        let blocdef = BlocsCatalog.blocsCatalog[blocContent.type];
                        const asyncBloc = BlocsCatalog.asyncBlocs[blocContent.type];
                        const asyncBlocType = typeof asyncBloc;

                        if (blocdef) {
                            if (asyncBloc && asyncBlocType === "string" && __SERVERSIDE__) {
                                dispatch({ type: "MODULE", payload: BlocsCatalog.asyncBlocs[blocContent.type] });
                            }

                            const res = loadBloc(
                                logger, blocdef, currentPage, blocContent, data, dispatch, isBootstrap, isPageInit);
                            if (res) {
                                promises.push(res);
                            }
                        } else if (asyncBloc) {
                            if (typeof asyncBloc === "string") {
                                const modulename = asyncBloc;
                                const loadAsyncBloc = loadModule(modulename, dispatch, getState).then(() => {
                                    const currentBlocDef = BlocsCatalog.blocsCatalog[blocContent.type];
                                    if (currentBlocDef) {
                                        blocdef = currentBlocDef;
                                        setCurrent = true;
                                        return loadBloc(
                                            logger,
                                            currentBlocDef,
                                            currentPage,
                                            blocContent,
                                            data,
                                            dispatch,
                                            isBootstrap,
                                            isPageInit
                                        );
                                    }
                                });
                                promises.push(loadAsyncBloc);
                            } else if (asyncBloc.promise && asyncBlocMatchConstraint(asyncBloc, state)) {
                                const loadAsyncBloc = asyncBloc.promise().then(() => {
                                    const currentBlocDef = BlocsCatalog.blocsCatalog[blocContent.type];
                                    if (currentBlocDef) {
                                        blocdef = currentBlocDef;
                                        setCurrent = true;
                                        return loadBloc(
                                            logger,
                                            currentBlocDef,
                                            currentPage,
                                            blocContent,
                                            data,
                                            dispatch,
                                            isBootstrap,
                                            isPageInit
                                        );
                                    }
                                });
                                promises.push(loadAsyncBloc);
                            } else if (asyncBloc.module && asyncBlocMatchConstraint(asyncBloc, state)) {
                                const loadAsyncBloc = loadModule(asyncBloc.module, dispatch, getState).then(() => {
                                    blocdef = BlocsCatalog.blocsCatalog[blocContent.type];
                                    if (blocdef) {
                                        setCurrent = true;
                                        return loadBloc(
                                            logger,
                                            blocdef,
                                            currentPage,
                                            blocContent,
                                            data,
                                            dispatch,
                                            isBootstrap,
                                            isPageInit
                                        );
                                    }
                                });
                                promises.push(loadAsyncBloc);
                            }
                        }
                    });
                }
            });
        } catch (exception) {
            logger.error("page processing error", exception, exception.stack);
        }
    }

    // if (__SERVERSIDE__ && state.appMetaData?.trackStep) {
    //     state.appMetaData?.trackStep("init page blocs " + promises.length);
    // }
    logger.verbose("initcurrentblocs : " + promises.length);
    if (promises.length) {
        return Promise.all(promises).then(() => {
            if (setCurrent) {
                const currentstate = getState();
                if (currentstate.pages.currentPage.id === currentPage.id) {
                    actions.setCurrentPage(currentPage)(dispatch);
                }
            }

            if (__SERVERSIDE__ && state.appMetaData?.trackStep) {
                state.appMetaData?.trackStep("initcurrentblocs done");
            }

            return { hasAsyncUpdate: true };
        });
    }

    return Promise.resolve({ hasAsyncUpdate: false });
}

export function filterBlocs(
    event: States.IEventState,
    community: States.ICommunityState,
    datacontext: Entities.IPageDataContext,
    template: VisualTheme.IBlocTemplateBase | VisualTheme.IContentTemplateConfigProperties,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    templateIdx
) {
    if ((template as VisualTheme.IBlocTemplateBase).disabled
        && ((!event || !event.detail || !event.detail.showhiddenbloc)
        && (!community || !community.detail || !community.detail.showhiddenbloc))) return false;

    if ((template as VisualTheme.IBlocTemplateBase).disabled &&
     (event?.detail?.showhiddenbloc || community?.detail?.showhiddenbloc)) {
        // eslint-disable-next-line no-param-reassign
        (template as VisualTheme.IBlocTemplateBase).customCSSClass += " red-disabled";
        return true;
    }

    if (template.showIf) {
        if (!(template as any).showIfPredicate) {
            const pr = getPredicate(template.showIf);
            // eslint-disable-next-line no-param-reassign
            (template as any).showIfPredicate = pr;
        }

        const showBloc = (template as any).showIfPredicate(datacontext);

        if (!showBloc) {
            return false;
        }

        // Else, we need to continue
    }

    if (displayContent(
        datacontext.userstate,
        {
            requireConnexion: template.requireConnexion,
            requireRegistration: template.requireRegistration,
            community: datacontext.communitystate,
            event: datacontext.eventstate,
            membershipActive: template.membershipActive,
            requireMembership: template.requireMembership,
            membershipLevelIds: (template as any).membershipLevelIds
        }
    ) === false) {
        return false;
    }

    if (template.showblocbydate && event?.detail) {
        return checkIfShownByDate(template, event, datacontext);
    }
    return true;
}

function internalFilterBlocContent(
    user: States.IAppUserState,
    datacontext: Entities.IPageDataContext,
    content: VisualTheme.IBlocContentTemplateBase,
    contentIdx: number
) {
    const asyncconf = BlocsCatalog.asyncBlocsConditions[content.type];
    if (asyncconf) {
        const res = displayContent(user, {
            event: datacontext.eventstate,
            community: datacontext.communitystate,
            requireConnexion: asyncconf.mustBeLogged,
            requireRegistration: asyncconf.mustBeRegistered
            // todo community
        });
        if (!res) {
            return false;
        }
    }

    return filterBlocContent(user, datacontext, content, contentIdx);
}

export function filterBlocContent(
    user: States.IAppUserState,
    datacontext: Entities.IPageDataContext,
    content: VisualTheme.IBlocContentTemplateBase,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contentIdx: number
) {
    if (content.disabled) return false;

    if (displayContent(user, {
        requireConnexion: content.requireConnexion,
        requireRegistration: content.requireRegistration,
        community: datacontext.communitystate,
        event: datacontext.eventstate,
        membershipActive: content.membershipActive,
        requireMembership: content.requireMembership,
        membershipLevelIds: (content as any).membershipLevelIds
    }) === false) {
        return false;
    }

    if (content.scheduled) {
        let isOnSchedule = true;
        if (content.scheduled.start) {
            isOnSchedule = (moment() > moment(content.scheduled.start));
        }
        if (content.scheduled.end) {
            isOnSchedule = isOnSchedule && (moment() < moment(content.scheduled.end));
        }
        if (!isOnSchedule) return false;
    }

    if (content.showIf) {
        if (!(content as any).showIfPredicate) {
            const pr = getPredicate(content.showIf);
            // eslint-disable-next-line no-param-reassign
            (content as any).showIfPredicate = pr;
        }

        return (content as any).showIfPredicate(datacontext);
    }

    return true;
}

function loadBloc(
    logger: ILogger,
    blocdef: BlocsCatalog.IDynamicBlocDefinition,
    currentPage: States.ICurrentPageState,
    blocContent: VisualTheme.IBlocContentTemplateBase,
    data: States.IAppState,
    dispatch,
    isBootstrap: boolean,
    isPageInit: boolean
) {
    let blocdata;
    if (isBootstrap && currentPage.data && currentPage.data[blocContent.id]) {
        blocdata = currentPage.data[blocContent.id] || {};
    } else {
        const blocchanged = (blocstate) => {
            // eslint-disable-next-line no-param-reassign
            currentPage.data[blocContent.id] = blocstate;
            actions.setBlocData(currentPage.id, currentPage.pagelock, blocContent.id, blocstate)(dispatch);
        };

        const hasData = currentPage.data && !!currentPage.data[blocContent.id];
        blocdata = { ...currentPage.data && currentPage.data[blocContent.id] };
        // eslint-disable-next-line no-param-reassign
        currentPage.data = Object.assign({}, currentPage.data, {
            [blocContent.id]: blocdata
        });

        if ((isPageInit || !hasData) && blocdef.datainit) {
            const res = blocdef.datainit(currentPage, blocdata, blocContent, data, blocchanged, !isPageInit);
            if (res && res.then && typeof res.then === 'function') {
                return res.then(null, (err) => {
                    if (__SERVERSIDE__ && data.appMetaData?.trackStep) {
                        data.appMetaData?.trackStep("bloc load error " + blocContent.type + " " + blocContent.id);
                    }
                    logger.error("error loading bloc " + blocContent.type + " " + blocContent.id, err);
                });
            }

            // if (__SERVERSIDE__ && data.appMetaData?.trackStep) {
            //     data.appMetaData?.trackStep("bloc load done " + blocContent.type + " " + blocContent.id);
            // }
        }
    }
}
