import type { Store } from 'redux';
import { guid } from '@inwink/utils/methods/guid';
import * as moment from 'moment';
import { getCookie } from '@inwink/utils/cookies';
import { defaultPostHeaderForJsonData } from '../api';
import type { States } from './services';
import type { IRequestsWithActivity } from './apiaccessprovider.definition';

export type ActivityCategories = 'page' | 'event';

// let _store: Store<States.IAppState> = null;
let _sessionId: string = null;
let uid: string = guid();
// let lastActivity: Date = new Date();
if (typeof window !== "undefined") {
    const tmp = getCookie("iwuid");

    if (!tmp) {
        window.document.cookie = "iwuid=" + uid;
    } else {
        uid = tmp;
    }
}

declare function requestIdleCallback(callback: () => void): any;
// declare function cancelIdleCallback(id: any): void;

export interface IUserActivity {
    sid: string;
    uid: string;
    cat: string;
    kd: string;
    act: string;
    dat: string;
    loc: string;
    app?: string;
    pat?: string;
    pau?: string;
    pas?: string;
    en?: string;
    enid?: string;
    isa?: boolean;
    vd: string;
    br: string;
    brv: string;
    brl: string;
    pt: string;
    lng: string;
    cr: Date;

}

interface IUserActivityQueueItem {
    eventId?: string;
    communityId?: string;
    activity: IUserActivity;
}

let queue: IUserActivityQueueItem[] = [];

function getSid() {
    const tmp = sessionStorage.getItem("sid");
    if (tmp) {
        const tmpSession = JSON.parse(tmp);
        if (tmpSession.date) {
            const sessionDate = new Date(tmpSession.date);
            if (moment().diff(moment(sessionDate), "minutes") < 60) {
                _sessionId = tmpSession.sid;
            } else {
                _sessionId = null;
            }
        }
    }

    if (!_sessionId) {
        _sessionId = guid();
    }

    sessionStorage.setItem("sid", JSON.stringify({ date: new Date(), sid: _sessionId }));

    return _sessionId;
}

export const userActivityActions = {
    trackCurrentPage() {
        return (dispatch, getState: () => States.IAppState) => {
            setTimeout(() => {
                userActivityActions.trackActivity("page")(dispatch, getState);
            }, 0);
        };
    },

    getPageActivity(
        page: States.ICurrentPageState,
        lng: string,
        category: ActivityCategories,
        kind?: string,
        action?: string,
        data?: string
    ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (dispatch, getState: () => States.IAppState) => {
            const template = page && page.template;
            const activity: IUserActivity = {
                sid: _sessionId,
                cat: category,
                kd: kind,
                act: action,
                dat: data,
                loc: window.location.href,
                app: template && template.application,
                pat: template && template.contentType,
                pau: template && template.tinyUrl,
                pas: InWinkPreview ? "preview" : "published",

                br: window.navigator.product,
                brv: window.navigator.appVersion,
                brl: window.navigator.language,
                pt: window.navigator.platform,
                vd: window.navigator.vendor,
                lng: lng,
                cr: new Date(),
                uid: uid
            };

            if (page && page.context && page.context.entityKind) {
                activity.en = page.context.entityKind;
                activity.enid = page.context.entityid;
            }

            return activity;
        };
    },

    trackActivity(
        category: ActivityCategories,
        kind?: string,
        action?: string,
        data?: string,
        transform?: (act: IUserActivity) => IUserActivity
    ) {
        return (dispatch, getState: () => States.IAppState) => {
            if (__SERVERSIDE__) {
                return;
            }

            const sessionId = getSid();
            sessionStorage.setItem("sid", JSON.stringify({ date: new Date(), sid: sessionId }));

            const state = getState();
            let page = state.pages[state.pages.currentPageId] as States.ICurrentPageState;
            const send = (pageInfo: States.ICurrentPageState) => {
                let activity = userActivityActions.getPageActivity(
                    pageInfo,
                    state.i18n.currentLanguageCode,
                    category,
                    kind,
                    action,
                    data
                )(dispatch, getState);

                if (transform) {
                    activity = transform(activity);
                }
                userActivityActions.sendActivity(activity)(dispatch, getState);
            };

            if (page && page.ready && page.ready.then) {
                page.ready.then((res) => {
                    page = res.page || page;
                    send(page);
                });
            } else {
                send(page);
            }
        };
    },

    sendActivity: (activity: IUserActivity) => {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            // lastActivity = activity.cr;
            if (state.event?.requestManagers) {
                queue.push({
                    eventId: state.event.eventid,
                    activity: activity
                });

                requestIdleCallback(() => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    checkQueue(dispatch, getState);
                });
            }

            if (state.community?.requestManagers) {
                queue.push({
                    communityId: state.community.communityid,
                    activity: activity
                });

                requestIdleCallback(() => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    checkQueue(dispatch, getState);
                });
            }
        };
    }
};

let currentCheck = Promise.resolve();
export function checkQueue(dispatch, getState: () => States.IAppState) {
    const result = currentCheck.then(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return processQueue(dispatch, getState);
    });

    result.then(() => {
        if (currentCheck === result) {
            currentCheck = Promise.resolve();
        }
    });

    currentCheck = result;
}

function processQueue(dispatch, getState: () => States.IAppState) {
    if (queue && queue.length) {
        const state = getState();
        if (!state.user.checked) {
            setTimeout(() => {
                processQueue(dispatch, getState);
            }, 200);

            return Promise.resolve();
        }

        const promise = Promise.resolve();
        const queueCopy = queue;
        const remainingItems = [...queueCopy];
        queue = [];

        const result = queueCopy.reduce((prom, item) => {
            return prom.then(() => {
                return new Promise((resolve, reject) => {
                    requestIdleCallback(() => {
                        const mgr = state.event?.requestManagers || state.community?.requestManagers;
                        // eslint-disable-next-line @typescript-eslint/no-use-before-define
                        return sendQueueItem(item, mgr).then(() => {
                            const itemidx = remainingItems.indexOf(item);
                            if (itemidx >= 0) {
                                remainingItems.splice(itemidx, 1);
                            }
                        }).then(resolve, reject);
                    });
                });
            });
        }, promise);

        return result.then(null, (err) => {
            console.error("process activity queue error", err);
            if (remainingItems.length && queue.length) {
                queue = [...remainingItems, ...queue];
            }
        });
    }

    return Promise.resolve();
}
function processQueueNow(dispatch, getState: () => States.IAppState): Promise<any> {
    if (queue && queue.length) {
        const state = getState();
        const mgr = state.event?.requestManagers || state.community?.requestManagers;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return Promise.all(queue.map((q) => sendQueueItem(q, mgr)));
    }

    return Promise.resolve();
}

function sendQueueItem(item: IUserActivityQueueItem, eventRequests: IRequestsWithActivity) {
    const url = item.eventId
        ? item.eventId + "/activity/log"
        : 'community/' + item.communityId + '/activity/log';

    return eventRequests.apiActivity.postJson(
        url,
        JSON.stringify(item.activity),
        Object.assign({}, defaultPostHeaderForJsonData,
            {
                disableAuthenticationBubbling: true
            })
    ).then(null, (err) => {
        if (err?.status && err?.status === 401) {
            return;
        }
        console.error("error logging activity", err);
        return Promise.reject(err);
    });
}

export function initActivityTracking(store: Store<States.IAppState>) {
    // _store = store;
    _sessionId = getSid();

    if (typeof window !== "undefined") {
        window.addEventListener("online", () => {
            processQueue(store.dispatch, store.getState);
        });

        const trackLeave = () => {
            userActivityActions.trackActivity("page", "leave", null, null)(store.dispatch, store.getState);
            processQueueNow(store.dispatch, store.getState);
        };
        window.addEventListener("beforeunload", trackLeave);
        window.addEventListener("pagehide", trackLeave);
    }
}
