import * as React from 'react';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import type { IItemTemplateProps } from '@@components/templates/itemtemplate';

interface IProductItemPrice extends IItemTemplateProps {
}

export class ProductItemPrice extends React.Component<IProductItemPrice, any> { 
    render() {
        const { customcontext, templateOptions } = this.props.datacontext;
        const { data } = this.props;

        return (
            <div className="price">
                {templateOptions?.showPriceHT
                    ? <h4 className='priceht'>
                        {customcontext.orderManager.formatPrice(data?.basePriceHt)}
                        {!templateOptions?.showPriceVAT && 
                        <AppTextLabel
                            className='price-detail'
                            component="span"
                            i18n="orderregistration.basket.price.ht"
                        />
                        }
                    </h4>
                    : <h4 className='pricettc'>
                        {customcontext.orderManager.formatPrice(data?.basePriceTtc)}
                        {!templateOptions?.showPriceVAT && 
                        <AppTextLabel
                            className='price-detail'
                            component="span"
                            i18n="orderregistration.basket.price.ttc"
                        />
                        }
                    </h4>
                }
                {templateOptions?.showPriceVAT &&
                    <AppTextLabel
                        component="div"
                        className="vat bloc-lighttext remark"
                        i18n={`orderregistration.basket.${templateOptions?.showPriceHT ? "pricehtvat" : "pricevat"}`}
                        inject={
                            {
                                vat: customcontext.orderManager.formatPrice(data?.vatAmount)
                            }
                        }
                    />
                }
            </div>
        );
    }
}
