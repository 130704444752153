import { Entities } from '@inwink/entities/entities';

export function checkIfNetworkingAllowed(conf : Entities.IEventDetailConfigurationNetworking,
    user: Entities.IAppUser | Entities.IPerson, checkAllowNetworking = true) {
    if (!user) {
        return false;
    }

    if (checkAllowNetworking && !user.allowNetworking) {
        return false;
    }

    if (!conf || !conf.hasNetworking) {
        return false;
    }

    if (conf.allowedPersonKinds && conf.allowedPersonKinds.length) {
        if (!user.kinds || !user.kinds.length || !user.kinds.some((k) => conf.allowedPersonKinds.indexOf(k) >= 0)) {
            return false;
        }
    }

    return true;
}
