// export { init, setEvent, send } from '../modules/cmspreview/messaging';

export function generateLoadingElt() {
    const elt = document.createElement("div");
    elt.style.position = "fixed";
    elt.style.bottom = "8px";
    elt.style.left = "8px";
    elt.style.borderRadius = "4px";
    elt.style.backgroundColor = "rgba(0,0,0,0.5)";
    elt.style.fontSize = "18pt";
    elt.style.color = "#FFFFFF";
    elt.style.padding = "16px";
    // eslint-disable-next-line max-len
    elt.innerHTML = `<div style="display:flex; align-items:center">Refresh... <div class="loaderindicator ring big"><i class="spinner" style="border: 4px solid rgba(255,255,255, 0.4); border-top-color: rgba(255,255,255, 0.8);"></i></div></div>`;
    return elt;
}

export function getCMSPreview() {
    return import(/* webpackChunkName: "cmsmessaging" */ '../modules/cmspreview/messaging');
}