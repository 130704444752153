import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { logging } from '@inwink/logging';
import { initCurrentPageBlocs } from '../services/pageservice';
import { loadLocale } from '../services/i18nservice';
import { whenDocumentReady } from '../client';
import {
    loadModule, rootWebsiteModule, eventInitModule, communityInitModule
} from '../routes/appmodules';
import { States } from '../services/services';
// import { AppShell } from '../components/appshell';
import { AppSplash } from '../components/appsplash';
import { AppVisualState } from '../components/appvisualstate';
import { getCMSPreview } from '../services/previewservice';
import { appSplashActions } from '../services/splashservice';
import { beforeHideSplash } from './appstate';

const logger = logging.getLogger("AppBootstrap");

export function bootstrap(startupId:string, store, history, initialState: States.IAppState, startPromises: Promise<any>[]) {
    logger.debug('prepare rendering components');

    const tmp = (global as any);
    if (InWinkPreview) {
        startPromises.push(getCMSPreview().then((previewservice) => {
            previewservice.init(store);
            (inwink as any).previewservice = previewservice;
        }));
    }

    if (tmp.INITIAL_STATE) {
        const state: States.IAppState = store.getState();
        startPromises.push(loadLocale(
            state.i18n.currentLanguageCode,
            state.i18n.currentDateDisplayLanguageCode,
            inwink.config.assetsUrl || '/'
        ));

        if (state.appMetaData.loadedModules && state.appMetaData.loadedModules.length) {
            startPromises.push(
                Promise.all(state.appMetaData.loadedModules.map((m) => loadModule(m, store.dispatch, store.getState)))
            );
        }
    }

    let init: Promise<any> = Promise.all(startPromises);
    if (tmp.INITIAL_STATE) {
        const state: States.IAppState = store.getState();

        if (state.pages.currentPage) {
            const currentpage: States.ICurrentPageState = state.pages[state.pages.currentPage.id];
            init = init.then(() => {
                if (console && console.timeStamp) {
                    console.timeStamp('Init page');
                }

                const pageinit = initCurrentPageBlocs(
                    currentpage,
                    currentpage.template,
                    state,
                    store.dispatch,
                    store.getState,
                    true
                );
                if (pageinit) { return pageinit; }
            });
        }
    }

    init.then(() => whenDocumentReady()).then(() => {
        const approot = document.getElementById('approot') as HTMLElement;
        if (navigator.userAgent.indexOf('iP') !== -1) { approot.classList.add('ios'); }
        if (navigator.userAgent.indexOf('Android') !== -1) { approot.classList.add('android'); }

        if (console && console.timeStamp) {
            console.timeStamp('Hydrate content');
        }
        logger.debug('Hydrate content');

        if (startupId && initialState.event?.eventid) {
            let rootPath = "/";
            if (initialState.community?.communityid) {
                rootPath = "/e/:eventtinyurl";
            }
            eventInitModule().then((mod) => {
                mod.initEventInfoProvider();
                hydrateRoot(approot, <Provider store={store}>
                    <Router history={history}>
                        <Route
                            path={rootPath}
                            render={(props) => <AppSplash>
                                <AppVisualState><mod.AppShell {...props} /></AppVisualState>
                            </AppSplash>}
                        />
                    </Router>
                </Provider>);
                initApp(store, approot);
            });
        } else if (startupId && initialState.community?.communityid) {
            let rootPath = "/";
            if (initialState.community?.parentcommunityid) {
                rootPath = "/c/:eventtinyurl";
            }
            communityInitModule().then((mod) => {
                mod.initEventInfoProvider();
                hydrateRoot(approot, <Provider store={store}>
                    <Router history={history}>
                        <Route
                            path={rootPath}
                            render={(props) => <AppSplash>
                                <AppVisualState><mod.CommunityShell {...props} /></AppVisualState>
                            </AppSplash>}
                        />
                    </Router>
                </Provider>);
                initApp(store, approot);
            });
        } else if (initialState.rootwebsite?.websiteid) {
            if (initialState.rootwebsite.isPreview) {
                rootWebsiteModule(store.dispatch, store.getState).then((rootwebsitemodule) => {
                    const Shell = rootwebsitemodule.RootWebsiteShell;
                    rootwebsitemodule.initEventInfoProvider();
                    hydrateRoot(approot, <Provider store={store}>
                        <Router history={history}>
                            <Route
                                path="/w/:customerid/:tenantid/:websiteid"
                                render={(props) => <AppSplash>
                                    <AppVisualState><Shell {...props} /></AppVisualState>
                                </AppSplash>}
                            />
                        </Router>
                    </Provider>);
                    initApp(store, approot);
                });
            } else {
                rootWebsiteModule(store.dispatch, store.getState).then((rootwebsitemodule) => {
                    const Shell = rootwebsitemodule.RootWebsiteShell;
                    rootwebsitemodule.initEventInfoProvider();
                    hydrateRoot(approot, <Provider store={store}>
                        <Router history={history}>
                            <Route
                                path="/"
                                render={(props) => <AppSplash>
                                    <AppVisualState><Shell {...props} /></AppVisualState>
                                </AppSplash>}
                            />
                        </Router>
                    </Provider>);
                    initApp(store, approot);
                });
            }
        } else if (initialState.event?.eventid) {
            eventInitModule().then((mod) => {
                mod.initEventInfoProvider();
                hydrateRoot(approot, <Provider store={store}>
                    <Router history={history}>
                        <Route
                            path="/:eventtinyurl"
                            render={(props) => <AppSplash>
                                <AppVisualState><mod.AppShell {...props} /></AppVisualState>
                            </AppSplash>}
                        />
                    </Router>
                </Provider>);
                initApp(store, approot);
            });
        } else if (initialState.community?.communityid) {
            communityInitModule().then((mod) => {
                mod.initEventInfoProvider();
                hydrateRoot(approot, <Provider store={store}>
                    <Router history={history}>
                        <Route
                            path="/:eventtinyurl"
                            render={(props) => <AppSplash>
                                <AppVisualState><mod.CommunityShell {...props} /></AppVisualState>
                            </AppSplash>}
                        />
                    </Router>
                </Provider>);
                
                initApp(store, approot);
            });
        }
        if (__DEBUG__) {
            console.timeEnd("INWINK STARTUP INTERACTIVE");
        }
    });
}

interface IHydrateProps {
    resolution: () => void;
    children: React.ReactElement;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Hydrate(props: IHydrateProps) {
    React.useEffect(() => {
        props.resolution();
    }, []);

    return props.children;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function initApp(store, approot: HTMLElement) {
    setTimeout(() => {
        const tmp = (global as any);
        // approot.style.display = "";
        if (global.inwink && (global.inwink as any).previewservice) {
            (inwink as any).previewservice.send({
                type: 'CompanionPreviewReady',
            });
        }

        if (typeof tmp.INITIAL_STATE !== "undefined") {
        // Delete initial data so that subsequent data fetches can occur:
            delete tmp.INITIAL_STATE;
        }
        // if (typeof tmp.inwinkWebsiteBootstrap !== "undefined") {
        //     tmp.inwinkWebsiteBootstrap = undefined;
        // }
        // if (typeof tmp.inwinkEventBootstrap !== "undefined") {
        //     tmp.inwinkEventBootstrap = undefined;
        // }
        // if (typeof tmp.inwinkCommunityBootstrap !== "undefined") {
        //     tmp.inwinkCommunityBootstrap = undefined;
        // }

        const state = store.getState();
        beforeHideSplash(state, store).then(() => {
            appSplashActions.unregisterService('webclientbootstrap')(store.dispatch, store.getState);
            logger.info('Remove splash');
        });
    }, 10);
}
