import { States } from './services';
import { i18nReducer } from './i18nservice';
import { pageReducer } from './pageservice';
import { userReducer } from './userservice';
import { userMessageReducer } from './usermessage';
import { trackingReducer } from './trackingservice';
import { appSplashReducer } from './splashservice';
import { appMetaDataReducer } from './appmetadataservice';
import type { ITargetInfo } from '../api/front/mapping';
import type { IRequestManager } from './apiaccessprovider.definition';

const _urlStates: {
    owner: any,
    urlState: States.IAppUrlContext
}[] = [];

export function getUrlState() {
    if (_urlStates.length) {
        return _urlStates[_urlStates.length - 1].urlState;
    }
    return null;
}

export function setUrlState(owner: any, urlState: States.IAppUrlContext) {
    if (!__SERVERSIDE__) {
        if (urlState) {
            const existing = _urlStates.find((s) => s.owner === owner);
            if (existing) {
                existing.urlState = urlState;
            } else {
                _urlStates.push({
                    owner: owner,
                    urlState: urlState
                });
            }
        } else {
            const existing = _urlStates.find((s) => s.owner === owner);
            const idx = _urlStates.indexOf(existing);
            _urlStates.splice(idx, 1);
        }    
    }
}

export const rootReducers : Partial<Record<keyof States.IAppState, any>> = {
    appMetaData: appMetaDataReducer,
    i18n: i18nReducer,
    // event: eventReducer,
    // rootwebsite: rootWebsiteReducer,
    pages: pageReducer,
    user: userReducer,
    usermessage: userMessageReducer,
    // userChat: userChatReducer,
    tracking: trackingReducer,
    appsplash: appSplashReducer,
    // ssr: ssrReducer
};

export const asyncReducers : Partial<Record<keyof States.IAppState, any>> = {
};

let targetInfoProvider: (requestmgr: IRequestManager, targetkey: string) => Promise<ITargetInfo> = null;

export function getTargetInfoProvider() {
    return targetInfoProvider;
}

export function setTargetInfoProvider(provider: (requestmgr: IRequestManager, targetkey: string) => Promise<ITargetInfo>) {
    targetInfoProvider = provider;
}

export const GuidPattern = new RegExp('^[0-9A-F]{8}[-]?(?:[0-9A-F]{4}[-]?){3}[0-9A-F]{12}$', 'i');