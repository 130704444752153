/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { IInwinkStore } from 'store';
import { States } from '@@services/services';

export const modules : Record<string, (dispatch?: (action) => void, getState?: () => States.IAppState) => Promise<any>> = {
};

export const modulesActions = {
    getModule(name: string) {
        return (dispatch, getState) => {
            return loadModule(name, dispatch, getState);
        };
    }
};

export function loadModule(name: string, dispatch: (action) => void, getState: () => States.IAppState): Promise<any> {
    const appmodule = modules[name];
    if (appmodule) {
        return appmodule(dispatch, getState);
    }

    return Promise.resolve();
}

export function rootWebsiteModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-rootwebsite" });
    return import(/* webpackChunkName: "mod-rootwebsite" */ '../modules/rootwebsite/module.rootwebsite');
}
modules["mod-rootwebsite"] = rootWebsiteModule;

export function pictureGalleryItemModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-picturegalleryitem" });
    return import(/* webpackChunkName: "mod-picturegalleryitem" */ '../modules/blocs/picturegalery/picturegalleryitem');
}
modules["mod-picturegalleryitem"] = pictureGalleryItemModule;

export function controlOnboardingModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-onboarding" });
    return import(/* webpackChunkName: "mod-control-onboarding" */ '../modules/blocs/onboarding/bloc.onboarding');
}
modules["mod-control-onboarding"] = controlOnboardingModule;

export function controlPictureGalleryModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-picturegalery" });
    return import(/* webpackChunkName: "mod-control-picturegalery" */ '../modules/blocs/picturegalery/bloc.picturegallery');
}
modules["mod-control-picturegalery"] = controlPictureGalleryModule;

export function controlPluginModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-plugin" });
    return import(/* webpackChunkName: "mod-control-plugin" */ '../modules/blocs/plugin/bloc.plugin');
}
modules["mod-control-plugin"] = controlPluginModule;

export function controlQRCodeModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-qrcode" });
    return import(/* webpackChunkName: "mod-control-qrcode" */ '../modules/blocs/qrcode/bloc.qrcode');
}
modules["mod-control-qrcode"] = controlQRCodeModule;

export function controlAddAppToHomeModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-addapptohomescreen" });
    return import(
        /* webpackChunkName: "mod-control-addapptohomescreen" */
        '../modules/blocs/addapptohome/bloc.addapptohomescreen'
    );
}
modules["mod-control-addapptohomescreen"] = controlAddAppToHomeModule;

export function controlEntityTagsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-entitytags" });
    return import(/* webpackChunkName: "mod-control-entitytags" */ '../modules/blocs/entitytags/bloc.entitytags');
}
modules["mod-control-entitytags"] = controlEntityTagsModule;

export function controlVideoModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-video" });
    return import(/* webpackChunkName: "mod-control-video" */ '../modules/blocs/video/bloc.video');
}
modules["mod-control-video"] = controlVideoModule;

export function controlLinksToolbarModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-linkstoolbar" });
    return import(/* webpackChunkName: "mod-control-linkstoolbar" */ '../modules/blocs/linkstoolbar/bloc.linkstoolbar');
}
modules["mod-control-linkstoolbar"] = controlLinksToolbarModule;

export function controlHTMLBlocModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-htmlbloc" });
    return import(/* webpackChunkName: "mod-control-htmlbloc" */ '../components/dynamicpage/blocs/bloc.htmlcontent');
}
modules["mod-control-htmlbloc"] = controlHTMLBlocModule;

export function controlItemsListModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-control-itemslist" });
    return import(/* webpackChunkName: "mod-control-itemslist" */ '../components/dynamicpage/blocs/bloc.itemslist');
}
modules["mod-control-itemslist"] = controlItemsListModule;

export function entityDetailModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-entitydetail" });
    return import(/* webpackChunkName: "mod-entitydetail" */ '../components/dynamicpage/blocs/bloc.entitydetail');
}
modules["mod-entitydetail"] = entityDetailModule;

export function entityDetailV2Module(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-entitydetailv2" });
    return import(/* webpackChunkName: "mod-entitydetailv2" */ '../components/dynamicpage/blocs/bloc.entitydetailv2');
}
modules["mod-entitydetailv2"] = entityDetailV2Module;

export function blocContentActionsModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-bloccontentactions" });
    return import(/* webpackChunkName: "mod-bloccontentactions" */ '../components/templates/bloccontentactions');
}
modules["mod-bloccontentactions"] = blocContentActionsModule;

export function videoItemModule(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "mod-videoitem" });
    return import(/* webpackChunkName: "mod-videoitem" */ '../components/video');
}
modules["mod-videoitem"] = videoItemModule;

export function rootWebsiteActionsModule() {
    return import(/* webpackChunkName: "mod-rootwebsiteactions" */ '../modules/module.rootwebsite');
}
modules["mod-rootwebsiteactions"] = rootWebsiteActionsModule;

const userMessageLoadedHandlers = [];
let userMessageLoaded = false;

export function onUserMessageLoaded(callback) {
    userMessageLoadedHandlers.push(callback);
    if (userMessageLoaded) {
        callback();
    }

    return () => {
        const idx = userMessageLoadedHandlers.indexOf(callback);
        if (idx >= 0) {
            userMessageLoadedHandlers.splice(idx, 1);
        }
    };
}

export function userChatModule(store: IInwinkStore) {
    return import(/* webpackChunkName: "mod-userchat" */ '../services/userchatactions').then((mod) => {
        mod.register(store);
        return mod;
    });
}

export function userMessageModule() {
    return import(/* webpackChunkName: "mod-usermessageactions" */ '../modules/module.usermessage').then((mod) => {
        if (!userMessageLoaded && userMessageLoadedHandlers.length) {
            userMessageLoaded = true;
            const promises = [];
            userMessageLoadedHandlers.forEach((m) => {
                const res = m();
                if (res && res.then) {
                    promises.push(res);
                }
            });
            return Promise.all(promises).then(() => {
                return mod;
            });
        }
        return mod;
    });
}
modules["mod-usermessageactions"] = userMessageModule;

export function eventUserActionsModule() {
    return import(/* webpackChunkName: "mod-eventuseractions" */ '@@event/module.eventuseractions');
}
modules["mod-eventuseractions"] = eventUserActionsModule;

export function eventUserBootstrapModule() {
    return import(/* webpackChunkName: "mod-eventuserboot" */ '@@event/module.eventuserbootstrap');
}
modules["mod-eventuserboot"] = eventUserBootstrapModule;

export function eventActionQueueModule() {
    return import(/* webpackChunkName: "mod-eventactionqueue" */ '@@event/module.eventactionqueue');
}
modules["mod-eventactionqueue"] = eventActionQueueModule;

export function eventModule() {
    return import(/* webpackChunkName: "mod-event" */ '../modules/module.event');
}
modules["mod-event"] = eventModule;

export function eventUserModule() {
    return import(/* webpackChunkName: "mod-eventuser" */ '@@event/features/user');
}
modules["mod-eventuser"] = eventUserModule;

export function eventInitModule() {
    return import(/* webpackChunkName: "mod-initevent" */ '@@event/services/eventactions/init');
}
modules["mod-initevent"] = eventInitModule;

export function communityModule() {
    return import(/* webpackChunkName: "mod-community" */ '../modules/module.community');
}
modules["mod-community"] = communityModule;

export function communityInitModule() {
    return import(/* webpackChunkName: "mod-initcommunity" */ '@@community/services/communityactions/init');
}
modules["mod-initcommunity"] = communityModule;

export function communityUserBootstrapModule() {
    return import(/* webpackChunkName: "mod-communityuserboot" */ '@@community/module.communityuseractions');
}
modules["mod-communityuserboot"] = communityUserBootstrapModule;

export function excelModule() {
    return import(/* webpackChunkName: "mod-excel" */ '../data/excel');
}
modules["mod-excel"] = excelModule;

export function qrCodeModule() {
    return import(/* webpackChunkName: "mod-qrcode" */ '../data/qrcode');
}
modules["mod-qrcode"] = qrCodeModule;

export function trackerGoogleAnalytics(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-ga" });
    return import(/* webpackChunkName: "trk-ga" */ '../services/tracking/trackers/googleanalytics');
}
modules["trk-ga"] = trackerGoogleAnalytics;

export function trackerAdobeDTM(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-adtm" });
    return import(/* webpackChunkName: "trk-adtm" */ '../services/tracking/trackers/adobedtm');
}
modules["trk-adtm"] = trackerAdobeDTM;

export function trackerEnsighten(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-ens" });
    return import(/* webpackChunkName: "trk-ens" */ '../services/tracking/trackers/ensighten');
}
modules["trk-ens"] = trackerEnsighten;

export function trackerFBPixel(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-fbp" });
    return import(/* webpackChunkName: "trk-fbp" */ '../services/tracking/trackers/facebookpixel');
}
modules["trk-fbp"] = trackerFBPixel;

export function trackerGTM(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-gtm" });
    return import(/* webpackChunkName: "trk-gtm" */ '../services/tracking/trackers/googletagmanager');
}
modules["trk-gtm"] = trackerGTM;

export function trackerHubspot(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-hbsp" });
    return import(/* webpackChunkName: "trk-hbsp" */ '../services/tracking/trackers/hubspot');
}
modules["trk-hbsp"] = trackerHubspot;

export function trackerLinkedin(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-lnk" });
    return import(/* webpackChunkName: "trk-lnk" */ '../services/tracking/trackers/linkedin');
}
modules["trk-lnk"] = trackerLinkedin;

export function trackerMatomo(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-mtm" });
    return import(/* webpackChunkName: "trk-mtm" */ '../services/tracking/trackers/matomo');
}
modules["trk-mtm"] = trackerMatomo;

export function trackerTwitter(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "trk-twt" });
    return import(/* webpackChunkName: "trk-twt" */ '../services/tracking/trackers/twitterpixel');
}
modules["trk-twt"] = trackerTwitter;

export function consentInwink(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "cst-iw" });
    return import(/* webpackChunkName: "cst-iw" */ '../services/tracking/consent/inwinkcookie');
}
modules["cst-iw"] = consentInwink;

export function consentDidomi(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "cst-did" });
    return import(/* webpackChunkName: "cst-did" */ '../services/tracking/consent/didomi');
}
modules["cst-iw"] = consentDidomi;

export function consentCookiebot(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "cst-ckb" });
    return import(/* webpackChunkName: "cst-ckb" */ '../services/tracking/consent/cookiebot');
}
modules["cst-ckb"] = consentCookiebot;

export function consentQuantcast(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "cst-qua" });
    return import(/* webpackChunkName: "cst-qua" */ '../services/tracking/consent/quantcast');
}
modules["cst-qua"] = consentQuantcast;

export function consentAxeptio(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "cst-axe" });
    return import(/* webpackChunkName: "cst-axe" */ '../services/tracking/consent/axeptio');
}
modules["cst-axe"] = consentAxeptio;

export function consentOneTrust(dispatch: (action) => void, getState: () => States.IAppState) {
    __SERVERSIDE__ && dispatch && dispatch({ type: "MODULE", payload: "cst-one" });
    return import(/* webpackChunkName: "cst-one" */ '../services/tracking/consent/onetrust');
}
modules["cst-one"] = consentOneTrust;
